import { PatientAiSetting } from 'constants/enums.js';

export const AiAlertId = {
	RAILS: 1,
	PATIENT_SITTING: 2,
	PATIENT_STANDING: 3,
	PATIENT_FACE_DOWN: 4,
	PATIENT_LYING_SIDE: 5,
	PATIENT_LYING_FRONT: 6,
	PATIENT_LYING_RIGHT_SIDE: 7,
	FALL_DETECTION: 8,
	PATIENT_INACTIVE: 9,
	PATIENT_GETTING_OUT_OF_BED: 10,
	PATIENT_MOBILITY: 11,
	PATIENT_LYING_LEFT_SIDE: 12,
	HANDS_DISINFECTED: 13,
	LOW_IV_BAG: 14,
	PATIENT_GETTING_OUT_OF_CHAIR: 15,
	PATIENT_INACTIVE_INFORMATION: 16,
	HANDS_NOT_DISINFECTED: 17,
	COUGHING: 20,
	CRYING_BABY: 21,
	SCREAMING: 22,
	HELP: 23,
	LOW_PRIORITY_ALARM: 24,
	MEDIUM_PRIORITY_ALARM: 25,
	HIGH_PRIORITY_ALARM: 26,
	ALARM_OFF: 27,
	VITAL_SIGN_ALARM: 28,
	COVERED_RAILS: 29,
};

export const MeasurementAlertId = {
	BLOOD_GLUCOSE_HIGH: 1,
	HEART_RATE_HIGH: 2,
	HEART_RATE_LOW: 3,
	BODY_TEMPERATURE_LOW: 4,
	BODY_TEPERATURE_HIGH: 5,
	BODY_TEMPERATURE_CRITICAL_HIGH: 6,
	BMI_LOW: 7,
	BMI_HIGH: 8,
	BMI_EXTREMELY_HIGH: 9,
	BLOOD_PRESSURE_SYSTOLIC_LOW: 10,
	BLOOD_PRESSURE_DIASTOLIC_LOW: 11,
	BLOOD_PRESSURE_SYSTOLIC_CRITICAL_LOW: 12,
	BLOOD_PRESSURE_DIASTOLIC_CRITICAL_LOW: 13,
	BLOOD_PRESSURE_SYSTOLIC_HIGH: 14,
	BLOOD_PRESSURE_DIASTOLIC_HIGH: 15,
	BLOOD_PRESSURE_SYSTOLIC_CRITICAL_HIGH: 16,
	BLOOD_PRESSURE_DIASTOLIC_CRITICAL_HIGH: 17,
	OXYGEN_SATURATION_LOW: 18,
	OXYGEN_SATURATION_CRITICAL_LOW: 19,
};

export const AlertCategory = {
	MEASUREMENT: 'measurement',
	FALL_DETECTION: 'fallDetection',
	RAILS: 'railDown',
	GETTING_OUT_OF_BED: 'gettingOutOfBed',
	FALL_PREVENTION: 'fallPrevention',
	IV_BAG: 'ivBag',
	PATIENT_MOBILITY: 'patientMobility',
	PRESSURE_INJURY: 'pressureInjury',
	AI_VOICE_ANALYSIS: 'aiVoiceAnalysis',
	GETTING_OUT_OF_CHAIR: 'gettingOutOfChair',
};

export const AlertTypeToSettingTypeMap = {
	[AiAlertId.PATIENT_GETTING_OUT_OF_BED]: PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
	[AiAlertId.PATIENT_GETTING_OUT_OF_CHAIR]: PatientAiSetting.PATIENT_GETTING_OUT_OF_CHAIR,
	[AiAlertId.FALL_DETECTION]: PatientAiSetting.FALL_DETECTED,
	[AiAlertId.RAILS]: PatientAiSetting.RAILS,
	[AiAlertId.PATIENT_INACTIVE_INFORMATION]: PatientAiSetting.PRESSURE_INJURY,
	[AiAlertId.PATIENT_INACTIVE]: PatientAiSetting.PRESSURE_INJURY,
	[AiAlertId.PATIENT_SITTING]: PatientAiSetting.PRESSURE_INJURY,
	[AiAlertId.PATIENT_STANDING]: PatientAiSetting.PRESSURE_INJURY,
	[AiAlertId.PATIENT_FACE_DOWN]: PatientAiSetting.PRESSURE_INJURY,
	[AiAlertId.PATIENT_LYING_SIDE]: PatientAiSetting.PRESSURE_INJURY,
	[AiAlertId.PATIENT_LYING_FRONT]: PatientAiSetting.PRESSURE_INJURY,
	[AiAlertId.PATIENT_LYING_RIGHT_SIDE]: PatientAiSetting.PRESSURE_INJURY,
	[AiAlertId.PATIENT_LYING_LEFT_SIDE]: PatientAiSetting.PRESSURE_INJURY,
	[AiAlertId.COUGHING]: PatientAiSetting.AI_VOICE_ANALYSIS,
	[AiAlertId.CRYING_BABY]: PatientAiSetting.AI_VOICE_ANALYSIS,
	[AiAlertId.SCREAMING]: PatientAiSetting.AI_VOICE_ANALYSIS,
	[AiAlertId.HELP]: PatientAiSetting.AI_VOICE_ANALYSIS,
	[AiAlertId.LOW_PRIORITY_ALARM]: PatientAiSetting.AI_VOICE_ANALYSIS,
	[AiAlertId.MEDIUM_PRIORITY_ALARM]: PatientAiSetting.AI_VOICE_ANALYSIS,
	[AiAlertId.HIGH_PRIORITY_ALARM]: PatientAiSetting.AI_VOICE_ANALYSIS,
	[AiAlertId.ALARM_OFF]: PatientAiSetting.AI_VOICE_ANALYSIS,
	[AiAlertId.VITAL_SIGN_ALARM]: PatientAiSetting.AI_VOICE_ANALYSIS,
};
