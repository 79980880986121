import { AiAlertId, AlertCategory, MeasurementAlertId } from 'constants/alertEnums.js';
import translate from 'i18n-translations/translate.jsx';
import { AlertTypeToSettingTypeMap } from 'constants/alertEnums.js';
import {
	dangerSound,
	extremelyHighPressure,
	extremelyHighTemperature,
	extremelyLowOxygen,
	extremelyLowPressure,
	fallAttentionSound,
	highBloodGlucose,
	highBloodPressure,
	highHeartRate,
	highTemperature,
	ivBagSound,
	lowHeartRate,
	lowOxygen,
	lowTemperature,
	obese,
	overWeight,
	personPositionSound,
	railSound,
	underWeight,
} from 'constants/soundAlerts.js';

export const getAiVoiceMessageByType = type => {
	switch (type) {
		case AiAlertId.COUGHING:
			return translate('coughingDetected');
		case AiAlertId.CRYING_BABY:
			return translate('cryingBabyDetected');
		case AiAlertId.SCREAMING:
			return translate('screamingDetected');
		case AiAlertId.HELP:
			return translate('helpDetected');
		case AiAlertId.LOW_PRIORITY_ALARM:
			return translate('lowPriorityAlarmDetected');
		case AiAlertId.MEDIUM_PRIORITY_ALARM:
			return translate('mediumPriorityAlarmDetected');
		case AiAlertId.HIGH_PRIORITY_ALARM:
			return translate('highPriorityAlarmDetected');
		case AiAlertId.ALARM_OFF:
			return translate('alarmOffDetected');
		case AiAlertId.VITAL_SIGN_ALARM:
			return translate('vitalSignDetected');
		default:
			return translate('pleaseCheckPatient');
	}
};

export const getPressureInjuryMessageByType = type => {
	switch (type) {
		case AiAlertId.PATIENT_SITTING:
			return translate('patientSitting');
		case AiAlertId.PATIENT_STANDING:
			return translate('patientStanding');
		case AiAlertId.PATIENT_LYING_FRONT:
			return translate('patientLyingFront');
		case AiAlertId.PATIENT_LYING_SIDE:
			return translate('patientLyingSide');
		case AiAlertId.PATIENT_LYING_RIGHT_SIDE:
			return translate('patientLyingRightSide');
		case AiAlertId.PATIENT_LYING_LEFT_SIDE:
			return translate('patientLyingLeftSide');
		case AiAlertId.PATIENT_FACE_DOWN:
			return translate('patientFaceDown');
		case AiAlertId.PATIENT_INACTIVE:
		case AiAlertId.PATIENT_INACTIVE_INFORMATION:
			return translate('patientInactive');
		default:
			return translate('pleaseCheckPatient');
	}
};

export const getRailsMessageByType = type => {
	switch (type) {
		case AiAlertId.RAILS:
			return translate('railDownMessage');
		case AiAlertId.COVERED_RAILS:
			return translate('railNotVisible');
		default:
			return translate('pleaseCheckPatient');
	}
};

export const getNotificationOptions = ({ alertTypeId, deviceId, aiSettingList = [] }) => {
	const foundSettingTypeId = AlertTypeToSettingTypeMap?.[alertTypeId];
	const foundAiSettings = aiSettingList.find(item => item.deviceId === deviceId);
	if (!foundSettingTypeId || !foundAiSettings) {
		return [];
	}
	return foundAiSettings.settings.find(item => item.settingTypeId === foundSettingTypeId)?.notificationOptions || [];
};

const getMeasurementAlert = alertTypeId => {
	switch (alertTypeId) {
		case MeasurementAlertId.BLOOD_GLUCOSE_HIGH:
			return {
				voiceOver: highBloodGlucose,
				text: translate('highBloodGlucose'),
			};
		case MeasurementAlertId.HEART_RATE_LOW:
			return {
				voiceOver: lowHeartRate,
				text: translate('lowHeartRateDescription'),
			};
		case MeasurementAlertId.HEART_RATE_HIGH:
			return {
				voiceOver: highHeartRate,
				text: translate('highHeartRateDescription'),
			};
		case MeasurementAlertId.BODY_TEMPERATURE_LOW:
			return {
				voiceOver: lowTemperature,
				text: translate('lowTemperature'),
			};
		case MeasurementAlertId.BODY_TEPERATURE_HIGH:
			return {
				voiceOver: highTemperature,
				text: translate('highTemperature'),
			};
		case MeasurementAlertId.BODY_TEMPERATURE_CRITICAL_HIGH:
			return {
				voiceOver: extremelyHighTemperature,
				text: translate('extremelyHighTemperature'),
			};
		case MeasurementAlertId.BLOOD_PRESSURE_SYSTOLIC_LOW:
		case MeasurementAlertId.BLOOD_PRESSURE_DIASTOLIC_LOW:
		case MeasurementAlertId.BLOOD_PRESSURE_SYSTOLIC_CRITICAL_LOW:
		case MeasurementAlertId.BLOOD_PRESSURE_DIASTOLIC_CRITICAL_LOW:
			return {
				voiceOver: extremelyLowPressure,
				text: translate('extremelyLowBloodPressure'),
			};
		case MeasurementAlertId.BLOOD_PRESSURE_SYSTOLIC_HIGH:
		case MeasurementAlertId.BLOOD_PRESSURE_DIASTOLIC_HIGH:
			return {
				voiceOver: highBloodPressure,
				text: translate('highBloodPressure'),
			};
		case MeasurementAlertId.BLOOD_PRESSURE_SYSTOLIC_CRITICAL_HIGH:
		case MeasurementAlertId.BLOOD_PRESSURE_DIASTOLIC_CRITICAL_HIGH:
			return {
				voiceOver: extremelyHighPressure,
				text: translate('extremelyHighPressure'),
			};
		case MeasurementAlertId.BMI_LOW:
			return {
				voiceOver: underWeight,
				text: translate('underweight'),
			};
		case MeasurementAlertId.BMI_HIGH:
			return {
				voiceOver: overWeight,
				text: translate('overweight'),
			};
		case MeasurementAlertId.BMI_EXTREMELY_HIGH:
			return {
				voiceOver: obese,
				text: translate('obese'),
			};
		case MeasurementAlertId.OXYGEN_SATURATION_LOW:
			return {
				voiceOver: lowOxygen,
				text: translate('lowOxygen'),
			};
		case MeasurementAlertId.OXYGEN_SATURATION_CRITICAL_LOW:
			return {
				voiceOver: extremelyLowOxygen,
				text: translate('extremelyLowOxygen'),
			};
		default:
			return { voiceOver: null, text: null };
	}
};

const getAiAlert = alertTypeId => {
	switch (alertTypeId) {
		case AiAlertId.FALL_DETECTION:
			return {
				voiceOver: fallAttentionSound,
				text: translate('pleaseCheckPatient'),
				alertCategory: AlertCategory.FALL_DETECTION,
			};
		case AiAlertId.PATIENT_SITTING:
		case AiAlertId.PATIENT_STANDING:
		case AiAlertId.PATIENT_FACE_DOWN:
		case AiAlertId.PATIENT_LYING_FRONT:
		case AiAlertId.PATIENT_LYING_SIDE:
		case AiAlertId.PATIENT_LYING_RIGHT_SIDE:
		case AiAlertId.PATIENT_LYING_LEFT_SIDE:
		case AiAlertId.PATIENT_INACTIVE:
		case AiAlertId.PATIENT_INACTIVE_INFORMATION:
			return {
				voiceOver: personPositionSound,
				text: getPressureInjuryMessageByType(alertTypeId),
				alertCategory: AlertCategory.PRESSURE_INJURY,
			};
		case AiAlertId.PATIENT_GETTING_OUT_OF_BED:
			return {
				voiceOver: personPositionSound,
				text: translate('patientGettingOutOfBed'),
				alertCategory: AlertCategory.GETTING_OUT_OF_BED,
			};
		case AiAlertId.PATIENT_GETTING_OUT_OF_CHAIR:
			return {
				voiceOver: personPositionSound,
				text: translate('personGettingOutOfChairMessage'),
				alertCategory: AlertCategory.GETTING_OUT_OF_CHAIR,
			};
		case AiAlertId.RAILS:
		case AiAlertId.COVERED_RAILS:
			return {
				voiceOver: railSound,
				text: getRailsMessageByType(alertTypeId),
				alertCategory: AlertCategory.RAILS,
			};
		case AiAlertId.PATIENT_MOBILITY:
			return {
				voiceOver: personPositionSound,
				text: translate('personIsDetectedWalking'),
				alertCategory: AlertCategory.PATIENT_MOBILITY,
			};
		case AiAlertId.LOW_IV_BAG:
			return {
				voiceOver: ivBagSound,
				text: translate('ivBagCheck'),
				alertCategory: AlertCategory.IV_BAG,
			};
		case AiAlertId.COUGHING:
		case AiAlertId.CRYING_BABY:
		case AiAlertId.SCREAMING:
		case AiAlertId.HELP:
		case AiAlertId.LOW_PRIORITY_ALARM:
		case AiAlertId.MEDIUM_PRIORITY_ALARM:
		case AiAlertId.HIGH_PRIORITY_ALARM:
		case AiAlertId.ALARM_OFF:
		case AiAlertId.VITAL_SIGN_ALARM: {
			return {
				voiceOver: personPositionSound,
				text: getAiVoiceMessageByType(alertTypeId),
				alertCategory: AlertCategory.AI_VOICE_ANALYSIS,
			};
		}
		default:
			return {
				voiceOver: dangerSound,
				text: translate('pleaseCheckPatient'),
				alertCategory: null,
			};
	}
};

export const getAlertDetailsById = ({ alertTypeId, isAiAlert = false }) =>
	isAiAlert ? getAiAlert(alertTypeId) : getMeasurementAlert(alertTypeId);
