import { gatewayApi } from 'constants/global-variables.js';

export const getDeviceList = async (companyId, level, id) => {
	try {
		const { data } = await gatewayApi.get(`/v1/organizations/${companyId}/level/${level}/id/${id}/devices`);
		return data.devices;
	} catch (error) {
		return { error };
	}
};

export const getMonitoringDevices = async deviceId => {
	try {
		const { data } = await gatewayApi.get(`/v1/nurse-station/${deviceId}/monitoring-devices`);
		return data.participants;
	} catch (error) {
		return { error };
	}
};

export const getDeviceSetting = async (companyId, deviceId, settingTypeId) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${companyId}/devices/${deviceId}/settings?settingTypeId=${settingTypeId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};
