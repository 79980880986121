import React, { useEffect, useRef } from 'react';
import { APP_CONFIG } from 'constants/global-variables.js';

const BuildVersion = () => {
	const versionTimeout = useRef(null);

	const shouldDisplayReleaseVersion = reactAppBuildNumber =>
		APP_CONFIG.buildNumber && reactAppBuildNumber && APP_CONFIG.buildNumber !== reactAppBuildNumber;

	const startVersionTimeout = () => {
		const compareVersions = async () => {
			try {
				const response = await fetch('/env/react-config.js');
				let configText = await response.text();
				eval(configText);
				const buildNumber = window.__env__.VITE_BUILD_NUMBER;
				if (shouldDisplayReleaseVersion(buildNumber)) {
					location.reload();
				}
			} catch (err) {
				// eslint-disable-next-line no-console
				console.error(err);
			}
			start();
		};

		const start = () => {
			if (versionTimeout.current) {
				clearTimeout(versionTimeout.current);
			}
			const versionTimeoutInterval = +APP_CONFIG.versionCheckInterval || 1;
			versionTimeout.current = setTimeout(() => {
				compareVersions();
			}, 1000 * 60 * versionTimeoutInterval);
		};

		start();
	};

	useEffect(() => {
		startVersionTimeout();
	}, []);

	return <></>;
};

export default BuildVersion;
