import {
	AudioRoutingDeviceTypes,
	AudioRoutingOption,
	CompanyRoleObjects,
	PatientAiSetting,
	StatAlarmType,
	TalkToPatient,
	UserLanguage,
	UserRoles,
} from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import _ from 'lodash';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getUserRole } from 'infrastructure/auth.js';

const statAlarmCdnUrl = `${healthCareCdnUrl}admin/super-admin/stat-alarms/alarm-`;

export const NurseMenu = {
	ROUNDING: 27,
	WAITING_ROOM: 24,
	CHAT: 33,
	PATIENTS: 997,
	TEAMS: 34,
	MONITORING: 35,
	RPM: 28,
	WAITING_FOR_RESULTS: 43,
	CHECK_IN: 36,
	VISITORS: 55,
	LOGISTICS: 276,
	AMBIENT_MONITORING: 207,
	CARE_EVENTS: 197,
	HOSPITAL_AT_HOME: 308,
};

export const VirtualSitterMenu = {
	TEAMS: 34,
	MONITORING: 35,
	AMBIENT_MONITORING: 207,
};

export const PatientMenu = {
	HOME: 23,
	RECENT: 29,
	APPOINTMENTS: 26,
	HEALTH: 30,
	SUMMARY: 31,
	HELLO: 64,
};

export const DoctorMenu = {
	HOME: 23,
	WAITING_ROOM: 24,
	WAITING_ROOM_IN_PERSON: 105,
	PATIENTS: 25,
	HOSPITAL_AT_HOME: 308,
	APPOINTMENTS: 26,
	ROUNDING: 27,
	RPM: 28,
	LOGISTICS: 276,
	SESSIONS_QUEUE: 186,
};

export const SuperUserMenu = {
	ORGANIZATION: 19,
	CALL_LOGS: 20,
	USER_MANAGEMENT: 18,
	CONFIGURATION: 21,
	QUEUE_MANAGEMENT: 22,
	DASHBOARD: 192,
	FLEET_MANAGEMENT: 222,
};

export const DigitalClinicianMenu = {
	CHECK_IN: 36,
	PATIENTS: 25,
	RPM: 28,
	VISITS: 44,
	REGISTER_PATIENT: 45,
};

export const SuperAdminMenu = {
	COMPANIES: 17,
	USER_MANAGEMENT: 18,
	AUDIT_LOGS: 42,
};

export const AdminMenu = {
	USER_MANAGEMENT: 18,
	ORGANIZATION: 19,
	CALL_LOGS: 20,
	CONFIGURATION: 21,
	AUDIT_LOGS: 42,
	DASHBOARD: 192,
	FLEET_MANAGEMENT: 222,
};

export const UserSettingTypes = {
	Other: 4, // Company Settings
	Monitoring: 3,
	Navigation: 2,
	GeneralSettings: 1,
	CallsSettings: 8,
	AiSettings: 14,
};

export const MonitoringSettings = {
	RaiseAlert: 57,
	ContactNurses: 58,
	CallPatient: 59,
	WalkieTalkie: 60,
	MicrosoftTeams: 61,
	Call911: 62,
	NightVision: 12,
	EHR: 65,
	Notes: 37,
	CareEventsForNurses: 38,
	CareEventsForSafetyCompanions: 39,
	ForwardToNurses: 40,
	Precautions: 46,
	Privacy: 48,
	Recording: 49,
	DisableVisits: 15,
	PTZCam: 117,
	SmartWatchVitalSigns: 118,
	BiobeatVitalSigns: 119,
	StatAlarm: 144,
	LifeSignals: 145,
	EditRoomNameMonitoring: 147,
	EnableMultipleFeedAudio: 66,
	PatientCommunication: 202,
	MonitoringHealthData: 216,
	RTLS: 219,
	VerbalRedirection: 220,
	PatientOutOfRoom: 221,
	MixedMonitoringView: 235,
	PatientInfo: 251,
	Whiteboard: 252,
	DisplayControl: 286,
	DiscontinueMonitoring: 287,
	PatientName: 297,
	PatientAge: 298,
	NurseNameNumber: 299,
	NurseAssistantNameNumber: 300,
	TlNameNumber: 301,
	PrimaryAdverseEvent: 302,
	PrimaryPatientCondition: 303,
	PrimaryPatientRiskFactor: 304,
	PreferredLanguage: 305,
	TitleBarNotes: 306,
	AdditionalPatientNotes: 307,
	Mindray: 999111,
};

export const CompanySettings = {
	CLIENT_THEME_COLOR: 1,
	TWO_FACTOR_TYPE: 2,
	LENGTH_UNIT: 4,
	WEIGHT_UNIT: 5,
	TEMPERATURE_UNIT: 6,
	HEIGHT_UNIT: 7,
	BLOOD_GLUCOSE_UNIT: 8,
	DATE_FORMAT: 9,
	TIME_FORMAT: 10,
	LANGUAGE: 11,
	TALK_TO_PATIENT_ROUTE: 101,
	MONITORING_ROUTE: 102,
	VIEW_PATIENT_ROUTE: 103,
	E2EE: 106,
	DIAL_OUT: 108,
	MULTIPARTY_CALL: 123,
	HELLO_DEVICE: 121,
	HUDDLE_CAMERA: 122,
	VCP_DISPLAY_NAME: 250,
	DOCTOR_DISPLAY_NAME: 314,
	BEING_MONITORED_MESSAGE: 261,
	THEME: 3,
	PATIENT_VIRTUAL_ADMISSION: 203,
	PATIENT_MANUAL_ADMISSION: 204,
	PATIENT_EHR_ADMISSION: 205,
	DIGITAL_BACKGROUND: 190,
	HS_DEFAULT_BACKGROUND: 191,
	HELLO_DEFAULT_BACKGROUND: 196,
	HELLO_DEFAULT_WALLPAPER: 208,
	SUPPORT_CHAT: 211,
	CAMERA_USE: 218,
	AUDIO_CALL_BACKGROUND: 322,
};

export const RouteSetting = {
	TALK_TO_PATIENT_ROUTE: 101,
	MONITORING_ROUTE: 102,
	VIEW_PATIENT_ROUTE: 103,
};

export const UserSettings = {
	CALL_BACKGROUND: 194,
};

export const CompanyCallSettings = {
	PROVIDER_MIC: 195,
	PATIENT_MIC: 200,
	PROVIDER_CAMERA: 199,
	PATIENT_CAMERA: 201,
	ROUNDING_DEFAULT_CAMERA: 282,
	MONITORING_DEFAULT_CAMERA: 283,
	RS_PROVIDER_MIC: 309,
	RS_PATIENT_MIC: 310,
	RS_PROVIDER_CAM: 311,
	RS_PATIENT_CAM: 312,
};

export const ConfigurableCallSettings = [
	{
		key: CompanyCallSettings.PROVIDER_MIC,
		title: 'providerMic',
		name: 'providerMic',
		description: 'providerMicDesc',
		icon: `${healthCareCdnUrl}microphone/mic-black.svg`,
	},
	{
		key: CompanyCallSettings.PATIENT_MIC,
		title: 'patientMicrophone',
		name: 'patientMicrophone',
		description: 'patientMicDesc',
		icon: `${healthCareCdnUrl}microphone/mic-black.svg`,
	},
	{
		key: CompanyCallSettings.PROVIDER_CAMERA,
		title: 'providerCamera',
		name: 'providerCamera',
		description: 'disableProviderCameraDesc',
		icon: `${healthCareCdnUrl}admin/super-admin/talk-to-patient.svg`,
	},
	{
		key: CompanyCallSettings.PATIENT_CAMERA,
		title: 'patientCamera',
		name: 'patientCamera',
		description: 'patientCameraDesc',
		icon: `${healthCareCdnUrl}admin/super-admin/talk-to-patient.svg`,
	},
];

export const ConfigurableRoomSignCallSettings = [
	{
		key: CompanyCallSettings.RS_PROVIDER_MIC,
		title: 'providerMic',
		name: 'rsProviderMic',
		description: 'providerMicDesc',
		icon: `${healthCareCdnUrl}microphone/mic-black.svg`,
	},
	{
		key: CompanyCallSettings.RS_PATIENT_MIC,
		title: 'patientMicrophone',
		name: 'rsPatientMic',
		description: 'patientMicDesc',
		icon: `${healthCareCdnUrl}microphone/mic-black.svg`,
	},
	{
		key: CompanyCallSettings.RS_PROVIDER_CAM,
		title: 'providerCamera',
		name: 'rsProviderCamera',
		description: 'disableProviderCameraDesc',
		icon: `${healthCareCdnUrl}admin/super-admin/talk-to-patient.svg`,
	},
	{
		key: CompanyCallSettings.RS_PATIENT_CAM,
		title: 'patientCamera',
		name: 'rsPatientCamera',
		description: 'patientCameraDesc',
		icon: `${healthCareCdnUrl}admin/super-admin/talk-to-patient.svg`,
	},
];

export const IntegrationTypesSettings = {
	ALEXA: 124,
	TELEHEALTH: 148,
	EVIDEON: 149,
	PCARE: 150,
	GET_WELL: 151,
	SONIFI: 152,
	AZURE_AD: 153,
	PING_FEDERATE: 154,
	OKTA: 254,
	HILL_ROM: 223,
	EPIC: 155,
	CERNER: 156,
	GLOBO: 157,
	AMN: 158,
	HL7_V2: 237,
	MDM: 241,
	DUO_SSO: 281,
};

export const HelloCareEngagementSettings = {
	HOME: 172,
	EDUCATION: 173,
	LIVE_TV: 174,
	MOVIES: 175,
	MUSIC: 176,
	MOODS: 177,
	APPS: 178,
	FAMILY_FRIENDS: 179,
	MEALS: 180,
	WHITEBOARD: 181,
	CARE_TEAM: 182,
	HEALTH_DATA: 187,
	PODCASTS: 242,
	GAMES: 243,
	HDMI_IN: 244,
	ROOM_CONTROLS: 245,
	SETTINGS: 260,
	WHITEBOARD_PIP: 273,
	VIRTUAL_NURSE: 323,
};

export const WhiteboardSettings = {
	WHITEBOARD_URL: 226,
	PIN: 246,
};

export const configurableWhiteboardSettings = () => ({
	[WhiteboardSettings.WHITEBOARD_URL]: getConfigurableMenu({
		title: 'whiteboardURL',
		value: 'false',
		isLocked: false,
		isURLField: true,
		valid: true,
		description: 'engagementWhiteboardURLDescription',
		category: 'whiteboardSettings',
	}),
	[WhiteboardSettings.PIN]: getConfigurableMenu({
		title: 'userAutoGeneratedPin',
		value: false,
		isLocked: false,
		description: 'autoGeneratedPinDesc',
		category: 'whiteboardSettings',
	}),
});

export const configurableHelloCareEngagement = () => ({
	[HelloCareEngagementSettings.HOME]: getConfigurableMenu({
		title: 'homeWidget',
		value: true,
		isLocked: false,
		description: 'engagementHomeDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.EDUCATION]: getConfigurableMenu({
		title: 'educationWidget',
		value: true,
		isLocked: false,
		description: 'engagementEducationDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.LIVE_TV]: getConfigurableMenu({
		title: 'liveTvWidget',
		value: true,
		isLocked: false,
		description: 'engagementLiveTVDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.MOVIES]: getConfigurableMenu({
		title: 'moviesWidget',
		value: true,
		isLocked: false,
		description: 'engagementMoviesDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.MUSIC]: getConfigurableMenu({
		title: 'musicWidget',
		value: true,
		isLocked: false,
		description: 'engagementMusicDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.MOODS]: getConfigurableMenu({
		title: 'moodsWidget',
		value: true,
		isLocked: false,
		description: 'engagementMoodsDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.APPS]: getConfigurableMenu({
		title: 'appsWidget',
		value: true,
		isLocked: false,
		description: 'engagementAppsDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.FAMILY_FRIENDS]: getConfigurableMenu({
		title: 'familyAndFriendsWidget',
		value: true,
		isLocked: false,
		description: 'engagementFamilyFriendsDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.MEALS]: getConfigurableMenu({
		title: 'orderMealsWidget',
		value: true,
		isLocked: false,
		description: 'engagementOrderMealDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.WHITEBOARD]: getConfigurableMenu({
		title: 'whiteBoardWidget',
		value: true,
		isLocked: false,
		description: 'engagementWhiteboardDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.CARE_TEAM]: getConfigurableMenu({
		title: 'careTeamWidget',
		value: true,
		isLocked: false,
		description: 'engagementCareTeamDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.HEALTH_DATA]: getConfigurableMenu({
		title: 'healthDataWidget',
		value: true,
		isLocked: false,
		description: 'engagementHealthDataDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.PODCASTS]: getConfigurableMenu({
		title: 'podcastsWidget',
		value: true,
		isLocked: false,
		description: 'podcastsWidgetDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.GAMES]: getConfigurableMenu({
		title: 'gamesWidget',
		value: true,
		isLocked: false,
		description: 'gamesWidgetDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.HDMI_IN]: getConfigurableMenu({
		title: 'hdmiInWidget',
		value: true,
		isLocked: false,
		description: 'hdmiInWidgetDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.ROOM_CONTROLS]: getConfigurableMenu({
		title: 'roomControlsWidget',
		value: true,
		isLocked: false,
		description: 'roomControlsWidgetDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.SETTINGS]: getConfigurableMenu({
		title: 'settingsWidget',
		value: true,
		isLocked: false,
		description: 'settingsWidgetDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.WHITEBOARD_PIP]: getConfigurableMenu({
		title: 'whiteboardPiPWidget',
		value: true,
		isLocked: false,
		description: 'whiteboardPiPWidgetDescription',
		category: 'helloCareEngagementSettings',
	}),
	[HelloCareEngagementSettings.VIRTUAL_NURSE]: getConfigurableMenu({
		title: 'virtualNurse',
		value: false,
		isLocked: false,
		description: 'virtualNurseAvatarDescription',
		category: 'helloCareEngagementSettings',
	}),
});

export const MonitoringFeatureFlagCategories = {
	GENERAL: 'general',
	COMMUNICATE_WITH_PATIENT: 'communicatingWithThePatient',
	COMMUNICATE_WITH_NURSES: 'communicatingWithNurses',
	AUTOMATIC_ALERTS: 'automaticAlerts',
	PATIENT_INFO: 'patientInfo',
};

export const RoundingFeaturesCategories = {
	GENERAL: 'general',
	LIVE_EXAMINATIONS: 'liveExaminations',
	INVITING_PEOPLE: 'invitingPeople',
	MEDICAL_INFO: 'medicalInfo',
	PATIENT_HISTORY: 'patientHistory',
};
export const AiSettingFeaturesCategory = {
	GENERAL_SETTINGS: 'generalSettings',
	AI_SETTINGS: 'aiSettings',
};

export const PatientTabsFeaturesCategories = {
	PATIENTS: 'patients',
};

export const RoomTypesFeaturesCategories = {
	ROOM_TYPES_SETTINGS: 'roomTypesSettings',
};

export const UserExperienceFeaturesCategories = {
	USER_EXPERIENCE: 'userExperience',
};

export const GeneralAndMenuFeaturesCategories = {
	GENERAL_SETTINGS: 'generalSettings',
	MENU_SETTINGS: 'menuSettings',
};

export const IntegrationFeaturesCategories = {
	EXTERNAL_IDENTITY_PROVIDERS: 'externalIdentityProviders',
	EHR: 'ehr',
	PATIENT_ENGAGEMENT: 'patientEngagement',
	TRANSLATION_SERVICES: 'translationServices',
	ALEXA: 'alexaEnabled',
	NURSE_CALLS_SYSTEMS: 'nurseCallsSystems',
};

export const NoteVariants = {
	SHOW_UNDER_TILE: 'show-under-tile',
	SHOW_ON_EXPAND: 'show-on-expand',
};

export const MixedViewVariants = {
	VIDEO_MONITORING: 'video-monitoring',
	AMBIENT_MONITORING: 'ambient-monitoring',
};

export const CallerNameVariant = {
	FULL_NAME: 'fullname',
	CREDENTIALS: 'credentials',
};

export const SettingsCategory = {
	GENERAL: 1,
	CUSTOMIZATION: 2,
	MONITORING: 3,
	OTHER: 4,
	MOBILE_LIVE_EXAMINATIONS: 5,
	TV_NAVIGATION: 6,
	ROUNDING: 7,
	CALL: 8,
	AUDIO_ROUTING: 9,
	INTEGRATIONS: 10,
	HELLO_CARE_ENGAGEMENT: 11,
	AI_SETTINGS: 14,
	PATIENTS: 15,
	USER_EXPERIENCE: 16,
	DASHBOARD_REPORTS: 17,
	WHITEBOARD: 18,
	ROOM_TYPES: 19,
	VOICE_COMMANDS: 21,
};

export const VoiceCommandsSettings = {
	VC_EMERGENCY_ASSISTANCE: 315,
	VC_CARE_EVENTS: 316,
	VC_PRIVACY_ENABLING: 317,
	VC_CALL_NURSE: 318,
};

export const AiSetting = {
	DISABLE_AI: 16,
	EWS_AI_VITAL_SIGNS: 109,
	GET_OUT_OF_BED: 110,
	RAILS: 111,
	PATIENT_MOBILITY: 112,
	FALL_DETECTION: 113,
	HAND_WASHING: 114,
	AI_PRIVACY_MODE: 115,
	PRESSURE_INJURY: 120,
	IV_BAG_FLUID_LEVEL: 170,
	DATA_COLLECTION: 217,
	AI_DETECTION_DRAWING: 256,
	SOUND_ON_AI_ALERT: 257,
	SILENT_MODE: 277,
	AI_VOICE_ANALYSIS: 319,
	ALERTS_EVIDENCE: 321,
	GET_OUT_OF_CHAIR: 370,
};

export const PatientTabs = {
	OVERVIEW: 227,
	FILES: 229,
	HEALTH: 230,
	PHYSICAL_THERAPY: 231,
	VISITS: 232,
	ALERT_HISTORY: 233,
};

export const ConfigSettingType = {
	TOGGLE: 'toggle',
	SELECT: 'select',
	LOCK: 'lock',
};

export const RoundingSettings = {
	ExamKit: 91,
	Wearables: 92,
	MedicalDevices: 93,
	AiMeasurements: 94,
	BiobeatPatch: 125,
	LifeSignalsPatch: 126,
	RoundingVisits: 134,
	RoundingHealthData: 135,
	RoundingAlertHistory: 132,
	PatientDiagnoses: 127,
	PatientProcedures: 128,
	PatientNotes: 129,
	PatientPrescriptions: 130,
	PatientPhysicalTherapy: 131,
	ScreenSharing: 136,
	FitToScreen: 137,
	GridView: 138,
	RoundingNightVision: 139,
	MeetingUrl: 140,
	InviteViaSms: 141,
	TranslationServices: 142,
	InviteViaEmail: 143,
	DialIn: 107,
	DialOut: 108,
	EditRoomNameRounding: 146,
	SessionWrapUp: 185,
	CareEventsForNurses: 188,
	CareEventsForSafetyCompanions: 189,
	DefaultHelloBackground: 196,
	CaregiversName: 193,
	LiveCaptions: 198,
	TalkToPatient: 224,
	ViewPatient: 225,
	RoundingCareEvents: 253,
	RateCall: 255,
	Whiteboard: 274,
	CancelInvite: 279,
	RoomSign: 280,
	DisplayControl: 284,
};

export const GeneralAndMenuSettings = {
	MONITORING_PRECAUTIONS_LEGEND: 14,
	COMPANIES: 17,
	USER_MANAGEMENT: 18,
	ORGANIZATION: 19,
	CALL_LOGS: 20,
	CONFIGURATION: 21,
	QUEUE_MANAGEMENT: 22,
	HOME: 23,
	WAITING_ROOM: 24,
	PATIENTS: 25,
	APPOINTMENTS: 26,
	ROUNDING: 27,
	RPM: 28,
	RECENT: 29,
	HEALTH: 30,
	SUMMARY: 31,
	CHAT: 33,
	TEAMS: 34,
	MONITORING: 35,
	CHECK_IN: 36,
	AUDIT_LOGS: 42,
	WAITING_FOR_RESULTS: 43,
	VISITS: 44,
	REGISTER_PATIENT: 45,
	POOLING_FLOW: 51,
	CONVERSATION_HISTORY: 52,
	NOTIFICATIONS: 53,
	MONITORING_SESSIONS: 54,
	VISITORS: 55,
	HELLO: 64,
	CONVERSATION_FILES: 104,
	CONVERSATION_PHOTOS: 105,
	SESSIONS_QUEUE: 186,
	DASHBOARD: 192,
	AMBIENT_MONITORING: 207,
	SUPPORT_CHAT: 211,
	CARE_EVENTS: 197,
	IS_OLD_USER_EXPERIENCE: 234,
	FLEET_MANAGEMENT: 222,
	IS_EXPERIENCE_HS_CHANGEABLE: 236,
	LOGISTICS: 276,
	AI_NOTIFICATIONS: 285,
	HOSPITAL_AT_HOME: 308,
	REGISTRATION_FORM: 313,
};

export const RoomModesSettings = {
	HELLOCARE: 169,
	AI_WIDGET: 161,
	WIDGET: 162,
	DIGITAL_CLINICIAN: 163,
	TELEMEDICINE: 164,
	HOSPITAL: 165,
	NICU: 159,
	VIRTUAL_EXAMINATION: 166,
	SIMPLIFIED: 167,
	E_VIDEON: 168,
	HELLOCARE_ENGAGEMENT: 160,
	HOSPITAL_AT_HOME: 999,
};

export const RoomTypesSettings = {
	BABY_ROOM: 262,
	FRONT_DESK: 263,
	DOCTOR_ROOM: 264,
	LABORATORY_ROOM: 265,
	INJECTION_ROOM: 266,
	INFUSION_ROOM: 267,
	DIGITAL_CLINIC: 268,
	PHARMACY_ROOM: 269,
	RADIOLOGY_ROOM: 270,
	KIOSK: 271,
	TRIAGE: 272,
};

export const DashboardSettings = {
	OVERVIEW: 238,
	PROVIDER_CENTRIC: 239,
	PATIENT_CENTRIC: 240,
	AI_ALERTS: 278,
	PATIENT_REPORT_DOWNLOAD_BUTTON: 171,
	HAND_HYGIENE: 320,
};

export const CallWorkflowType = {
	MONITORING: 1,
	ROUNDING: 2,
	SILENT_MODE: 3,
	BACKGROUND: 4,
};

const getConfigurableMenu = config => ({ ...config });

export const getConfig = config =>
	config
		? {
				isLocked: config.isLocked,
				value: JSON.parse(config.value),
				description: config.description,
		  }
		: { isLocked: false, value: false, description: '' };

export const TelemedicineModesSettings = {
	INTAKE_FORM: 183,
	DOCTOR_POOLING: 184,
	RAPID_CALL: 247,
	REGULAR_CALL: 248,
	ADMISSION_CALL: 249,
	SUBMIT_NEW_CASE: 259,
	SHARED_QUEUE: 275,
};

export const IntakeFormVariants = {
	SCP_HEALTH_FORM: 'scp-health-form',
	BAYCARE_FORM: 'baycare-form',
};

export const configurableVoiceCommandsSettings = () => ({
	[VoiceCommandsSettings.VC_EMERGENCY_ASSISTANCE]: getConfigurableMenu({
		title: 'voiceCommandForEmergencyAssistance',
		value: false,
		description: 'vCEADesc',
		category: 'voiceCommandsSettings',
	}),
	[VoiceCommandsSettings.VC_CARE_EVENTS]: getConfigurableMenu({
		title: 'voiceCommandForCareEvents',
		value: false,
		description: 'vCCEDesc',
		category: 'voiceCommandsSettings',
	}),
	[VoiceCommandsSettings.VC_PRIVACY_ENABLING]: getConfigurableMenu({
		title: 'voiceCommandForPrivacyEnabling',
		value: false,
		description: 'vCPDesc',
		category: 'voiceCommandsSettings',
	}),
	[VoiceCommandsSettings.VC_CALL_NURSE]: getConfigurableMenu({
		title: 'voiceCommandForCallNurse',
		value: false,
		description: 'vCCNDesc',
		category: 'voiceCommandsSettings',
		variant: { value: UserLanguage.ENGLISH, label: 'english' },
		options: [
			{ value: UserLanguage.ENGLISH, label: 'english' },
			{ value: UserLanguage.ARABIC, label: 'arabic' },
		],
	}),
});

export const configurableDashboardSettings = () => ({
	[DashboardSettings.OVERVIEW]: getConfigurableMenu({
		title: 'overview',
		value: true,
		description: 'overviewDescription',
		category: 'dashboardSettings',
	}),
	[DashboardSettings.PROVIDER_CENTRIC]: getConfigurableMenu({
		title: 'careTeamReport',
		value: true,
		description: 'providerCentricReportDescription',
		category: 'dashboardSettings',
	}),
	[DashboardSettings.PATIENT_CENTRIC]: getConfigurableMenu({
		title: 'patientReport',
		value: true,
		description: 'patientCentricReportDescription',
		category: 'dashboardSettings',
	}),
	[DashboardSettings.AI_ALERTS]: getConfigurableMenu({
		title: 'aiAlerts',
		value: true,
		description: 'aiAlertsDescription',
		category: 'dashboardSettings',
	}),
	[DashboardSettings.PATIENT_REPORT_DOWNLOAD_BUTTON]: getConfigurableMenu({
		title: 'downloadButtonAtPatientReport',
		value: false,
		description: 'downloadBtnPatientReportDesc',
		category: 'dashboardSettings',
	}),
	[DashboardSettings.HAND_HYGIENE]: getConfigurableMenu({
		title: 'dashboardHandHygiene',
		value: false,
		description: 'hHDashboardDesc',
		category: 'dashboardSettings',
	}),
});

const TelemedicineModesCategories = {
	GENERAL: 'general',
	DOCTOR_LIST: 'doctorList',
	QUEUE_MANAGEMENT: 'queueManagement',
	SHARED_QUEUE: 'sharedQueue',
};

export const configurableTelemedicineRoomModesSettings = () => ({
	[TelemedicineModesSettings.INTAKE_FORM]: getConfigurableMenu({
		title: 'intakeForm',
		value: false,
		description: 'intakeFormDescription',
		variant: { value: IntakeFormVariants.SCP_HEALTH_FORM, label: 'scpHealthForm' },
		options: [
			{ value: IntakeFormVariants.SCP_HEALTH_FORM, label: 'scpHealthForm' },
			{ value: IntakeFormVariants.BAYCARE_FORM, label: 'baycareForm' },
		],
		category: TelemedicineModesCategories.GENERAL,
	}),
	[TelemedicineModesSettings.DOCTOR_POOLING]: getConfigurableMenu({
		title: 'doctorPooling',
		value: false,
		description: 'doctorPoolingDescription',
		category: TelemedicineModesCategories.DOCTOR_LIST,
	}),
	[TelemedicineModesSettings.RAPID_CALL]: getConfigurableMenu({
		title: 'rapidCall',
		value: false,
		description: 'rapidCallDescription',
		category: TelemedicineModesCategories.QUEUE_MANAGEMENT,
	}),
	[TelemedicineModesSettings.REGULAR_CALL]: getConfigurableMenu({
		title: 'regularCall',
		value: false,
		description: 'regularCallDescription',
		category: TelemedicineModesCategories.QUEUE_MANAGEMENT,
	}),
	[TelemedicineModesSettings.ADMISSION_CALL]: getConfigurableMenu({
		title: 'admissionCall',
		value: false,
		description: 'admissionCallDescription',
		category: TelemedicineModesCategories.QUEUE_MANAGEMENT,
	}),
	[TelemedicineModesSettings.SHARED_QUEUE]: getConfigurableMenu({
		title: 'sharedQueue',
		value: false,
		description: 'sharedQueueDescription',
		category: TelemedicineModesCategories.SHARED_QUEUE,
	}),
	[TelemedicineModesSettings.SUBMIT_NEW_CASE]: getConfigurableMenu({
		title: 'submitNewCase',
		value: false,
		description: 'submitNewCaseDescription',
		category: TelemedicineModesCategories.SHARED_QUEUE,
	}),
});

export const configurableRoomType = () => [
	{
		value: RoomTypes.DEFAULT.type,
		label: 'room',
	},
	{
		value: RoomTypes.BABY_ROOM.type,
		label: 'babyRoom',
	},
];

export const configurableRoomTypesSettings = () => ({
	[RoomTypesSettings.BABY_ROOM]: {
		title: 'babyRoom',
		value: false,
		description: 'babyRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.FRONT_DESK]: {
		title: 'frontDesk',
		value: false,
		description: 'frontDeskRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.DOCTOR_ROOM]: {
		title: 'doctor',
		value: false,
		description: 'doctorRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.LABORATORY_ROOM]: {
		title: 'laboratory',
		value: false,
		description: 'laboratoryRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.INJECTION_ROOM]: {
		title: 'injectionRoom',
		value: false,
		description: 'injectionRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.INFUSION_ROOM]: {
		title: 'infusionRoom',
		value: false,
		description: 'infusionRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.DIGITAL_CLINIC]: {
		title: 'digitalClinic',
		value: false,
		description: 'digitalClinicRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.PHARMACY_ROOM]: {
		title: 'pharmacy',
		value: false,
		description: 'pharmacyRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.RADIOLOGY_ROOM]: {
		title: 'radiology',
		value: false,
		description: 'radiologyRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.KIOSK]: {
		title: 'kiosk',
		value: false,
		description: 'kioskRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
	[RoomTypesSettings.TRIAGE]: {
		title: 'triage',
		value: false,
		description: 'triageRoomTypeDescription',
		category: RoomTypesFeaturesCategories.ROOM_TYPES_SETTINGS,
	},
});

export const configurableWorkflowTypes = () => [
	{
		value: CallWorkflowType.MONITORING,
		label: 'monitoring',
	},
	{
		value: CallWorkflowType.ROUNDING,
		label: 'rounding',
	},
	{
		value: CallWorkflowType.BACKGROUND,
		label: 'backgroundAI',
	},
];

export const SelectTypes = {
	TEAMS_SETTINGS: 1,
	ROLE_SETTINGS: 2,
};

export const configurableRoundingMenu = (role = UserRoles.ADMIN) => ({
	[RoundingSettings.EditRoomNameRounding]: getConfigurableMenu({
		title: 'editRoomNameRounding',
		value: false,
		description: 'editRoomNameRoundingDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.LiveCaptions]: getConfigurableMenu({
		title: 'liveCaptions',
		value: false,
		description: 'liveCaptionsDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.TalkToPatient]: getConfigurableMenu({
		title: 'talkToPatient',
		value: true,
		description: 'talkToPatientDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.CancelInvite]: getConfigurableMenu({
		title: 'cancelInvite',
		value: false,
		description: 'cancelInviteDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.RateCall]: getConfigurableMenu({
		title: 'rateCallQuality',
		value: true,
		description: 'rateCallQualityDesc',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.ViewPatient]: getConfigurableMenu({
		title: 'viewPatient',
		value: true,
		description: 'viewPatientDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.Whiteboard]: getConfigurableMenu({
		title: 'whiteboard',
		value: true,
		description: 'roundingWhiteboardDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.DisplayControl]: getConfigurableMenu({
		title: 'whiteboardControls',
		value: true,
		description: 'whiteboardControlsDesc',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.RoomSign]: getConfigurableMenu({
		title: 'roomSign',
		value: false,
		description: 'roundingRoomSignDescription',
		category: RoundingFeaturesCategories.GENERAL,
	}),
	[RoundingSettings.DialIn]: getConfigurableMenu({
		title: 'dialIn',
		value: false,
		description: 'dialInDescription',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
	}),
	[RoundingSettings.DialOut]: getConfigurableMenu({
		title: 'dialOut',
		value: false,
		description: 'dialOutDescription',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
	}),
	[RoundingSettings.InviteViaEmail]: getConfigurableMenu({
		title: 'inviteViaEmail',
		value: true,
		description: 'inviteViaEmailDescription',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
	}),
	[RoundingSettings.TranslationServices]: getConfigurableMenu({
		title: 'translationServices',
		value: false,
		description: 'translationServiceDescription',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
	}),
	[RoundingSettings.InviteViaSms]: getConfigurableMenu({
		title: 'inviteSms',
		value: true,
		description: 'inviteViaSmsDescription',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
	}),
	[RoundingSettings.MeetingUrl]: getConfigurableMenu({
		title: 'meetingUrl',
		value: true,
		description: 'meetingUrlDescription',
		category: RoundingFeaturesCategories.INVITING_PEOPLE,
	}),
	[RoundingSettings.ExamKit]: getConfigurableMenu({
		title: 'examKit',
		value: false,
		description: 'examKitDescription',
		category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
	}),
	[RoundingSettings.Wearables]: getConfigurableMenu({
		title: 'wearables',
		value: false,
		description: 'wearablesDescription',
		category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
	}),
	[RoundingSettings.MedicalDevices]: getConfigurableMenu({
		title: 'medicalDevices',
		value: false,
		description: 'medicalDevicesDescription',
		category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
	}),
	[RoundingSettings.AiMeasurements]: getConfigurableMenu({
		title: 'aiMeasurements',
		value: false,
		description: 'AIMeasurementsDescription',
		category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
	}),
	[RoundingSettings.BiobeatPatch]: getConfigurableMenu({
		title: 'biobeatPatch',
		value: false,
		description: 'biobeatPatchDescription',
		category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
	}),
	[RoundingSettings.LifeSignalsPatch]: getConfigurableMenu({
		title: 'lifesignalsPatch',
		value: false,
		description: 'lifeSignalsPatchDescription',
		category: RoundingFeaturesCategories.LIVE_EXAMINATIONS,
	}),
	[RoundingSettings.RoundingHealthData]: getConfigurableMenu({
		title: 'roundingHealthData',
		value: false,
		description: 'healthDataDescription',
		category: RoundingFeaturesCategories.PATIENT_HISTORY,
	}),
	[RoundingSettings.RoundingVisits]: getConfigurableMenu({
		title: 'roundingVisits',
		value: false,
		description: 'visitsDescription',
		category: RoundingFeaturesCategories.PATIENT_HISTORY,
	}),
	[RoundingSettings.RoundingAlertHistory]: getConfigurableMenu({
		title: 'alertHistory',
		value: false,
		description: 'alertHistoryDescription',
		category: RoundingFeaturesCategories.PATIENT_HISTORY,
	}),
	...([UserRoles.NURSE, UserRoles.SUPER_ADMIN, UserRoles.ADMIN].includes(role) && {
		[RoundingSettings.CareEventsForNurses]: getConfigurableMenu({
			title: 'roundingCareEventsForNurses',
			value: false,
			description: 'careEventsForNursesDescription',
			category: RoundingFeaturesCategories.PATIENT_HISTORY,
			disableDependencies: [RoundingSettings.CareEventsForSafetyCompanions],
		}),
		[RoundingSettings.CareEventsForSafetyCompanions]: getConfigurableMenu({
			title: 'careEventsForSafetyCompanions',
			value: false,
			description: 'careEventsForNursesDesc',
			category: RoundingFeaturesCategories.PATIENT_HISTORY,
			disableDependencies: [RoundingSettings.CareEventsForNurses],
		}),
		[RoundingSettings.RoundingCareEvents]: getConfigurableMenu({
			title: 'roundingCareEvents',
			value: false,
			description: 'roundingCareEventsDesc',
			category: RoundingFeaturesCategories.PATIENT_HISTORY,
		}),
	}),
	[RoundingSettings.CaregiversName]: getConfigurableMenu({
		title: 'displayCaregiverName',
		type: SelectTypes.ROLE_SETTINGS,
		value: false,
		isLocked: false,
		description: 'displayCaregiversNameDescription',
		category: RoundingFeaturesCategories.GENERAL,
		variant: { value: CallerNameVariant.FULL_NAME, label: 'displayFullName' },
		...([UserRoles.NURSE, UserRoles.DOCTOR].includes(role) && {
			options: [
				{ value: CallerNameVariant.FULL_NAME, label: 'displayFullName' },
				{ value: CallerNameVariant.CREDENTIALS, label: 'displayCredentialsName' },
			],
		}),
	}),
	...(role !== UserRoles.NURSE && {
		[RoundingSettings.PatientDiagnoses]: getConfigurableMenu({
			title: 'medicalInfoPatientDiagnosis',
			value: false,
			description: 'patientDiagnosesDescription',
			category: RoundingFeaturesCategories.MEDICAL_INFO,
		}),
		[RoundingSettings.PatientProcedures]: getConfigurableMenu({
			title: 'medicalInfoProcedures',
			value: false,
			description: 'proceduresDescription',
			category: RoundingFeaturesCategories.MEDICAL_INFO,
		}),
		[RoundingSettings.PatientNotes]: getConfigurableMenu({
			title: 'medicalInfoNotes',
			value: false,
			description: 'roundingNotesDescription',
			category: RoundingFeaturesCategories.MEDICAL_INFO,
		}),
		[RoundingSettings.PatientPrescriptions]: getConfigurableMenu({
			title: 'medicalInfoPrescriptions',
			value: false,
			description: 'prescriptionsDescription',
			category: RoundingFeaturesCategories.MEDICAL_INFO,
		}),
		[RoundingSettings.PatientPhysicalTherapy]: getConfigurableMenu({
			title: 'medicalInfoPhysicalTherapy',
			value: false,
			description: 'physicalTherapyDescription',
			category: RoundingFeaturesCategories.MEDICAL_INFO,
		}),
		[RoundingSettings.SessionWrapUp]: getConfigurableMenu({
			title: 'wrapUpPage',
			value: false,
			description: 'sessionWrapUpDescription',
			category: RoundingFeaturesCategories.GENERAL,
		}),
	}),
});
export const getConfigurationWithDiffTypeKey = (config, type) => +`999${config}${type}`;

export const configurableAISettings = () => ({
	[getConfigurationWithDiffTypeKey(AiSetting.SILENT_MODE, CallWorkflowType.MONITORING)]: getConfigurableMenu({
		title: 'fullySilentMode',
		value: false,
		isLocked: false,
		description: 'silentModeDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.SILENT_MODE,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.DATA_COLLECTION, CallWorkflowType.MONITORING)]: getConfigurableMenu({
		title: 'dataCollectionAlerts',
		value: false,
		isLocked: false,
		description: 'dataCollectionAlertsDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.DATA_COLLECTION,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.DATA_COLLECTION, CallWorkflowType.ROUNDING)]: getConfigurableMenu({
		title: 'dataCollectionAlerts',
		value: false,
		isLocked: false,
		description: 'dataCollectionAlertsDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.DATA_COLLECTION,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.DATA_COLLECTION, CallWorkflowType.BACKGROUND)]: getConfigurableMenu({
		title: 'dataCollectionAlerts',
		value: false,
		isLocked: false,
		description: 'dataCollectionAlertsDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.DATA_COLLECTION,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.AI_DETECTION_DRAWING, CallWorkflowType.MONITORING)]: getConfigurableMenu({
		title: 'aIDetectionDrawings',
		value: false,
		isLocked: false,
		description: 'aiDetectionDrawingsDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.AI_DETECTION_DRAWING,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.AI_DETECTION_DRAWING, CallWorkflowType.ROUNDING)]: getConfigurableMenu({
		title: 'aIDetectionDrawings',
		value: false,
		isLocked: false,
		description: 'aiDetectionDrawingsDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.AI_DETECTION_DRAWING,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.SOUND_ON_AI_ALERT, CallWorkflowType.MONITORING)]: getConfigurableMenu({
		title: 'soundOnAiAlert',
		value: false,
		isLocked: false,
		description: 'soundOnAiAlertDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.SOUND_ON_AI_ALERT,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.SOUND_ON_AI_ALERT, CallWorkflowType.ROUNDING)]: getConfigurableMenu({
		title: 'soundOnAiAlert',
		value: false,
		isLocked: false,
		description: 'soundOnAiAlertDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.SOUND_ON_AI_ALERT,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.ALERTS_EVIDENCE, CallWorkflowType.MONITORING)]: getConfigurableMenu({
		title: 'alertsEvidence',
		value: false,
		isLocked: false,
		description: 'alertsEvidenceDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.ALERTS_EVIDENCE,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.ALERTS_EVIDENCE, CallWorkflowType.ROUNDING)]: getConfigurableMenu({
		title: 'alertsEvidence',
		value: false,
		isLocked: false,
		description: 'alertsEvidenceDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.ALERTS_EVIDENCE,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.ALERTS_EVIDENCE, CallWorkflowType.BACKGROUND)]: getConfigurableMenu({
		title: 'alertsEvidence',
		value: false,
		isLocked: false,
		description: 'alertsEvidenceDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		isNotUserPreference: true,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.ALERTS_EVIDENCE,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.EWS_AI_VITAL_SIGNS, CallWorkflowType.MONITORING)]: getConfigurableMenu({
		title: 'ewsAiVitalSigns',
		value: false,
		isLocked: false,
		description: 'aIVitalSignsDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.EWS_AI_VITAL_SIGNS,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.EWS_AI_VITAL_SIGNS, CallWorkflowType.ROUNDING)]: getConfigurableMenu({
		title: 'ewsAiVitalSigns',
		value: false,
		isLocked: false,
		description: 'aIVitalSignsDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.EWS_AI_VITAL_SIGNS,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.EWS_AI_VITAL_SIGNS, CallWorkflowType.BACKGROUND)]: getConfigurableMenu({
		title: 'ewsAiVitalSigns',
		value: false,
		isLocked: false,
		description: 'aIVitalSignsDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.EWS_AI_VITAL_SIGNS,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.GET_OUT_OF_BED, CallWorkflowType.MONITORING)]: getConfigurableMenu({
		title: 'getOutOfBed',
		value: false,
		isLocked: false,
		description: 'gettingOutOfBedDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.GET_OUT_OF_BED,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.GET_OUT_OF_BED, CallWorkflowType.ROUNDING)]: getConfigurableMenu({
		title: 'getOutOfBed',
		value: false,
		isLocked: false,
		description: 'gettingOutOfBedDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.GET_OUT_OF_BED,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.GET_OUT_OF_BED, CallWorkflowType.BACKGROUND)]: getConfigurableMenu({
		title: 'getOutOfBed',
		value: false,
		isLocked: false,
		description: 'gettingOutOfBedDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_BED,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.GET_OUT_OF_BED,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.GET_OUT_OF_CHAIR, CallWorkflowType.MONITORING)]: getConfigurableMenu({
		title: 'getOutOfChair',
		value: false,
		isLocked: false,
		description: 'gettingOutOfChairDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_CHAIR,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.GET_OUT_OF_CHAIR,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.GET_OUT_OF_CHAIR, CallWorkflowType.ROUNDING)]: getConfigurableMenu({
		title: 'getOutOfChair',
		value: false,
		isLocked: false,
		description: 'gettingOutOfChairDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.PATIENT_GETTING_OUT_OF_CHAIR,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.GET_OUT_OF_CHAIR,
		isExpandable: true,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.RAILS, CallWorkflowType.MONITORING)]: getConfigurableMenu({
		title: 'rails',
		value: false,
		isLocked: false,
		description: 'railsDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.RAILS,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.RAILS,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.RAILS, CallWorkflowType.ROUNDING)]: getConfigurableMenu({
		title: 'rails',
		value: false,
		isLocked: false,
		description: 'railsDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.RAILS,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.RAILS,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.RAILS, CallWorkflowType.BACKGROUND)]: getConfigurableMenu({
		title: 'rails',
		value: false,
		isLocked: false,
		description: 'railsDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.RAILS,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.RAILS,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.IV_BAG_FLUID_LEVEL, CallWorkflowType.MONITORING)]: getConfigurableMenu({
		title: 'ivBagFluidLevel',
		value: false,
		isLocked: false,
		description: 'ivBagDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.IV_BAG_FLUID_LEVEL,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.IV_BAG_FLUID_LEVEL, CallWorkflowType.ROUNDING)]: getConfigurableMenu({
		title: 'ivBagFluidLevel',
		value: false,
		isLocked: false,
		description: 'ivBagDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.IV_BAG_FLUID_LEVEL,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.IV_BAG_FLUID_LEVEL, CallWorkflowType.BACKGROUND)]: getConfigurableMenu({
		title: 'ivBagFluidLevel',
		value: false,
		isLocked: false,
		description: 'ivBagDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.IV_BAG_FLUID_LEVEL,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.FALL_DETECTION, CallWorkflowType.MONITORING)]: getConfigurableMenu({
		title: 'fallDetection',
		value: false,
		isLocked: false,
		description: 'fallDetectionDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.FALL_DETECTED,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.FALL_DETECTION,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.FALL_DETECTION, CallWorkflowType.ROUNDING)]: getConfigurableMenu({
		title: 'fallDetection',
		value: false,
		isLocked: false,
		description: 'fallDetectionDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.FALL_DETECTED,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.FALL_DETECTION,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.FALL_DETECTION, CallWorkflowType.BACKGROUND)]: getConfigurableMenu({
		title: 'fallDetection',
		value: false,
		isLocked: false,
		description: 'fallDetectionDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.FALL_DETECTED,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.FALL_DETECTION,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.HAND_WASHING, CallWorkflowType.MONITORING)]: getConfigurableMenu({
		title: 'handWashing',
		value: false,
		isLocked: false,
		description: 'handWashingDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.HAND_WASHING,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.HAND_WASHING, CallWorkflowType.ROUNDING)]: getConfigurableMenu({
		title: 'handWashing',
		value: false,
		isLocked: false,
		description: 'handWashingDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.HAND_WASHING,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.HAND_WASHING, CallWorkflowType.BACKGROUND)]: getConfigurableMenu({
		title: 'handWashing',
		value: false,
		isLocked: false,
		description: 'handWashingDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.HAND_WASHING,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.AI_PRIVACY_MODE, CallWorkflowType.MONITORING)]: getConfigurableMenu({
		title: 'aiPrivacyMode',
		value: false,
		isLocked: false,
		description: 'aIPrivacyModeDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.AI_PRIVACY_MODE,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.AI_PRIVACY_MODE, CallWorkflowType.ROUNDING)]: getConfigurableMenu({
		title: 'aiPrivacyMode',
		value: false,
		isLocked: false,
		description: 'aIPrivacyModeDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.AI_PRIVACY_MODE,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.AI_PRIVACY_MODE, CallWorkflowType.BACKGROUND)]: getConfigurableMenu({
		title: 'aiPrivacyMode',
		value: false,
		isLocked: false,
		description: 'aIPrivacyModeDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.AI_PRIVACY_MODE,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.DISABLE_AI, CallWorkflowType.MONITORING)]: getConfigurableMenu({
		title: 'disableAiTemporarily',
		value: false,
		isLocked: false,
		description: 'disableAiDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.DISABLE_AI,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.DISABLE_AI, CallWorkflowType.ROUNDING)]: getConfigurableMenu({
		title: 'disableAiTemporarily',
		value: false,
		isLocked: false,
		description: 'disableAiDescription',
		category: AiSettingFeaturesCategory.GENERAL_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.DISABLE_AI,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.PRESSURE_INJURY, CallWorkflowType.MONITORING)]: getConfigurableMenu({
		title: 'pressureInjury',
		value: false,
		isLocked: false,
		description: 'inactiveTimeDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.PRESSURE_INJURY,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.PRESSURE_INJURY,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.PRESSURE_INJURY, CallWorkflowType.ROUNDING)]: getConfigurableMenu({
		title: 'pressureInjury',
		value: false,
		isLocked: false,
		description: 'inactiveTimeDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.PRESSURE_INJURY,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.PRESSURE_INJURY,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.PRESSURE_INJURY, CallWorkflowType.BACKGROUND)]: getConfigurableMenu({
		title: 'pressureInjury',
		value: false,
		isLocked: false,
		description: 'inactiveTimeDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		aiConfigurationTypeId: PatientAiSetting.PRESSURE_INJURY,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.PRESSURE_INJURY,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.PATIENT_MOBILITY, CallWorkflowType.MONITORING)]: getConfigurableMenu({
		title: 'patientMobility',
		value: false,
		isLocked: false,
		description: 'patientWalkingDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.PATIENT_MOBILITY,
		aiConfigurationTypeId: PatientAiSetting.PATIENT_MOBILITY,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.PATIENT_MOBILITY, CallWorkflowType.ROUNDING)]: getConfigurableMenu({
		title: 'patientMobility',
		value: false,
		isLocked: false,
		description: 'patientWalkingDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.PATIENT_MOBILITY,
		aiConfigurationTypeId: PatientAiSetting.PATIENT_MOBILITY,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.PATIENT_MOBILITY, CallWorkflowType.BACKGROUND)]: getConfigurableMenu({
		title: 'patientMobility',
		value: false,
		isLocked: false,
		description: 'patientWalkingDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.PATIENT_MOBILITY,
		aiConfigurationTypeId: PatientAiSetting.PATIENT_MOBILITY,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.AI_VOICE_ANALYSIS, CallWorkflowType.MONITORING)]: getConfigurableMenu({
		title: 'aiVoiceAnalysis',
		value: false,
		isLocked: false,
		description: 'aiVoiceDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.MONITORING,
		settingTypeId: AiSetting.AI_VOICE_ANALYSIS,
		aiConfigurationTypeId: PatientAiSetting.AI_VOICE_ANALYSIS,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.AI_VOICE_ANALYSIS, CallWorkflowType.ROUNDING)]: getConfigurableMenu({
		title: 'aiVoiceAnalysis',
		value: false,
		isLocked: false,
		description: 'aiVoiceDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.ROUNDING,
		settingTypeId: AiSetting.AI_VOICE_ANALYSIS,
		aiConfigurationTypeId: PatientAiSetting.AI_VOICE_ANALYSIS,
	}),
	[getConfigurationWithDiffTypeKey(AiSetting.AI_VOICE_ANALYSIS, CallWorkflowType.BACKGROUND)]: getConfigurableMenu({
		title: 'aiVoiceAnalysis',
		value: false,
		isLocked: false,
		description: 'aiVoiceDescription',
		category: AiSettingFeaturesCategory.AI_SETTINGS,
		callWorkflowTypeId: CallWorkflowType.BACKGROUND,
		settingTypeId: AiSetting.AI_VOICE_ANALYSIS,
		aiConfigurationTypeId: PatientAiSetting.AI_VOICE_ANALYSIS,
	}),
});

export const configurablePatientTabs = () => ({
	[PatientTabs.OVERVIEW]: getConfigurableMenu({
		title: 'overview',
		value: true,
		isLocked: false,
		description: 'overviewTab',
		category: PatientTabsFeaturesCategories.PATIENTS,
	}),
	[PatientTabs.FILES]: getConfigurableMenu({
		title: 'files',
		value: true,
		isLocked: false,
		description: 'filesTab',
		category: PatientTabsFeaturesCategories.PATIENTS,
	}),
	[PatientTabs.HEALTH]: getConfigurableMenu({
		title: 'health',
		value: true,
		isLocked: false,
		description: 'healthTab',
		category: PatientTabsFeaturesCategories.PATIENTS,
	}),
	[PatientTabs.PHYSICAL_THERAPY]: getConfigurableMenu({
		title: 'physicalTherapy',
		value: true,
		isLocked: false,
		description: 'physicalTherapyTab',
		category: PatientTabsFeaturesCategories.PATIENTS,
	}),
	[PatientTabs.VISITS]: getConfigurableMenu({
		title: 'visits',
		value: true,
		isLocked: false,
		description: 'visitTab',
		category: PatientTabsFeaturesCategories.PATIENTS,
	}),
	[PatientTabs.ALERT_HISTORY]: getConfigurableMenu({
		title: 'alertHistory',
		value: true,
		isLocked: false,
		description: 'alertHistoryTab',
		category: PatientTabsFeaturesCategories.PATIENTS,
	}),
});

export const configurableUserExperienceSettings = (role, isUserExperienceHSEditable) => ({
	...(((isUserExperienceHSEditable && UserRoles.ADMIN === role) || UserRoles.SUPER_ADMIN === role) && {
		[GeneralAndMenuSettings.IS_OLD_USER_EXPERIENCE]: getConfigurableMenu({
			title: 'isOldUserExperience',
			value: false,
			isLocked: false,
			description: 'isOldUserExperienceDescription',
			category: UserExperienceFeaturesCategories.USER_EXPERIENCE,
		}),
	}),
	...([UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.IS_EXPERIENCE_HS_CHANGEABLE]: getConfigurableMenu({
			title: 'isEnabledForHs',
			value: false,
			isLocked: false,
			description: 'isEnabledForHsDescription',
			category: UserExperienceFeaturesCategories.USER_EXPERIENCE,
		}),
	}),
});

export const configurableGeneralAndMenuSettings = (role = UserRoles.ADMIN) => ({
	...([UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.CONVERSATION_PHOTOS]: getConfigurableMenu({
			title: 'conversationPhotos',
			value: true,
			description: 'conversationPhotosDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.HOSPITAL_AT_HOME]: getConfigurableMenu({
			title: 'hospitalAtHome',
			value: true,
			description: 'hospitalAtHomeDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.CONVERSATION_FILES]: getConfigurableMenu({
			title: 'conversationFiles',
			value: true,
			description: 'conversationFilesDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.NOTIFICATIONS]: getConfigurableMenu({
			title: 'notifications',
			value: true,
			description: 'notificationsDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.CONVERSATION_HISTORY]: getConfigurableMenu({
			title: 'conversationWithPatient',
			value: true,
			description: 'conversationHistoryDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.LOGISTICS]: getConfigurableMenu({
			title: 'logistics',
			value: true,
			description: 'logisticsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.WAITING_ROOM]: getConfigurableMenu({
			title: 'waitingRoom',
			value: true,
			description: 'waitingRoomDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.ROUNDING]: getConfigurableMenu({
			title: 'rounding',
			value: true,
			description: 'roundingDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
	}),
	...([UserRoles.DOCTOR, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.HOME]: getConfigurableMenu({
			title: 'homePage',
			value: true,
			description: 'homePageDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.SESSIONS_QUEUE]: getConfigurableMenu({
			title: 'sessionsQueue',
			value: true,
			description: 'sessionsQueueDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.APPOINTMENTS]: getConfigurableMenu({
			title: 'appointments',
			value: true,
			description: 'apppointmentsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),

		[GeneralAndMenuSettings.REGISTRATION_FORM]: getConfigurableMenu({
			title: 'doctorRegistrationForm',
			value: false,
			description: 'registrationFormPhotosDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
	}),
	...([UserRoles.NURSE, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.AI_NOTIFICATIONS]: getConfigurableMenu({
			title: 'aiNotifications',
			value: true,
			description: 'aiNotificationsDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.CARE_EVENTS]: getConfigurableMenu({
			title: 'careEventsDashboard',
			value: true,
			description: 'careEventsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.CHAT]: getConfigurableMenu({
			title: 'chat',
			value: true,
			description: 'chatDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.MONITORING]: getConfigurableMenu({
			title: 'monitoring',
			value: true,
			description: 'monitoringDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.VISITORS]: getConfigurableMenu({
			title: 'visitors',
			value: true,
			description: 'visitorsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.POOLING_FLOW]: getConfigurableMenu({
			title: 'poolingFlow',
			value: true,
			description: 'poolingFlowDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.MONITORING_SESSIONS]: getConfigurableMenu({
			title: 'savedSessions',
			value: true,
			description: 'savedSessionsDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.AMBIENT_MONITORING]: getConfigurableMenu({
			title: 'careNotifications',
			value: true,
			description: 'ambientMonitoringDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
	}),
	...([UserRoles.VIRTUAL_SITTER, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.MONITORING]: getConfigurableMenu({
			title: 'monitoring',
			value: true,
			description: 'monitoringDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.MONITORING_SESSIONS]: getConfigurableMenu({
			title: 'savedSessions',
			value: true,
			description: 'savedSessionsDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.AMBIENT_MONITORING]: getConfigurableMenu({
			title: 'careNotifications',
			value: true,
			description: 'ambientMonitoringDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
	}),
	...([UserRoles.VIRTUAL_SITTER, UserRoles.NURSE, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.TEAMS]: getConfigurableMenu({
			title: 'teams',
			value: true,
			description: 'teamsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.MONITORING_PRECAUTIONS_LEGEND]: getConfigurableMenu({
			title: 'monitoringPrecautionsLegend',
			value: true,
			description: 'monitoringPrecautionsDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
	}),
	...([UserRoles.DIGITAL_CLINICIAN, UserRoles.DOCTOR, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.PATIENTS]: getConfigurableMenu({
			title: 'patients',
			value: true,
			description: 'patientsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
	}),
	...([UserRoles.DIGITAL_CLINICIAN, UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.RPM]: getConfigurableMenu({
			title: 'patientMonitoring',
			value: true,
			description: 'rpmDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
	}),
	...([UserRoles.SUPER_USER, UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.DASHBOARD]: getConfigurableMenu({
			title: 'dashboard',
			value: true,
			description: 'dashboardDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.FLEET_MANAGEMENT]: getConfigurableMenu({
			title: 'fleetDashboard',
			value: true,
			description: 'fleetDashboardDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.QUEUE_MANAGEMENT]: getConfigurableMenu({
			title: 'queueManagement',
			value: true,
			description: 'queueManagementDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.ORGANIZATION]: getConfigurableMenu({
			title: 'organization',
			value: true,
			description: 'organizationDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.CALL_LOGS]: getConfigurableMenu({
			title: 'callLogs',
			value: true,
			description: 'callLogsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.USER_MANAGEMENT]: getConfigurableMenu({
			title: 'userManagement',
			value: true,
			description: 'userManagementDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
		[GeneralAndMenuSettings.CONFIGURATION]: getConfigurableMenu({
			title: 'configurations',
			value: true,
			description: 'configurationsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
	}),
	...([UserRoles.SUPER_ADMIN].includes(role) && {
		[GeneralAndMenuSettings.SUPPORT_CHAT]: getConfigurableMenu({
			title: 'supportChat',
			value: true,
			description: 'supportChatDescription',
			category: GeneralAndMenuFeaturesCategories.GENERAL_SETTINGS,
		}),
		[GeneralAndMenuSettings.AUDIT_LOGS]: getConfigurableMenu({
			title: 'auditLogs',
			value: true,
			description: 'auditLogsDescription',
			category: GeneralAndMenuFeaturesCategories.MENU_SETTINGS,
		}),
	}),
});

export const configurableMonitoringMenu = (role, isCompanyOrHSLevel = false) => ({
	[MonitoringSettings.EditRoomNameMonitoring]: getConfigurableMenu({
		title: 'editRoomNameMonitoring',
		value: false,
		description: 'editRoomNameMonitoringDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.LifeSignals]: getConfigurableMenu({
		title: 'lifeSignals',
		value: false,
		isLocked: false,
		description: 'lifeSignalDescription',
		category: MonitoringFeatureFlagCategories.AUTOMATIC_ALERTS,
	}),
	[MonitoringSettings.StatAlarm]: getConfigurableMenu({
		title: 'statAlarm',
		value: false,
		isLocked: false,
		description: 'statAlarmDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
		hasButton: true,
		...(role === UserRoles.SUPER_ADMIN && {
			variant: { label: 'alarmCount', translateValue: 1, value: StatAlarmType.ALARM_1, url: `${statAlarmCdnUrl}1.mp3` },
			options: [
				{ label: 'alarmCount', translateValue: 1, value: StatAlarmType.ALARM_1, url: `${statAlarmCdnUrl}1.mp3` },
				{ label: 'alarmCount', translateValue: 2, value: StatAlarmType.ALARM_2, url: `${statAlarmCdnUrl}2.mp3` },
			],
		}),
	}),
	[MonitoringSettings.SmartWatchVitalSigns]: getConfigurableMenu({
		title: 'smartWatchVitalSigns',
		value: false,
		isLocked: false,
		description: 'smartWatchVitalSignsDescription',
		category: MonitoringFeatureFlagCategories.AUTOMATIC_ALERTS,
	}),
	[MonitoringSettings.BiobeatVitalSigns]: getConfigurableMenu({
		title: 'biobeatVitalSigns',
		value: false,
		isLocked: false,
		description: 'biobeatVitalSignsDescription',
		category: MonitoringFeatureFlagCategories.AUTOMATIC_ALERTS,
	}),
	[MonitoringSettings.PTZCam]: getConfigurableMenu({
		title: 'ptzCam',
		value: true,
		isLocked: false,
		description: 'pTZControlsDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.ContactNurses]: getConfigurableMenu({
		title: 'contactNurse',
		value: false,
		isLocked: true,
		description: 'contactNursesDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.CallPatient]: getConfigurableMenu({
		title: 'callPatient',
		value: false,
		isLocked: false,
		description: 'callPatientDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
	}),
	[MonitoringSettings.PatientCommunication]: getConfigurableMenu({
		title: 'patientCommunication',
		value: false,
		isLocked: false,
		description: 'patientCommunicationDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
	}),
	[MonitoringSettings.WalkieTalkie]: getConfigurableMenu({
		title: 'walkieTalkie',
		value: false,
		isLocked: true,
		description: 'walkieTalkieDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.RTLS]: getConfigurableMenu({
		title: 'rtls',
		value: false,
		isLocked: false,
		description: 'rtlsDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
	}),
	[MonitoringSettings.MicrosoftTeams]: getConfigurableMenu({
		title: 'microsoftTeams',
		value: false,
		isLocked: true,
		description: 'microsoftTeamsDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.Call911]: getConfigurableMenu({
		title: 'call911',
		value: false,
		isLocked: true,
		description: 'call911Description',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.NightVision]: getConfigurableMenu({
		title: 'nightVision',
		value: true,
		isLocked: false,
		description: 'nightVisionDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.EHR]: getConfigurableMenu({
		title: 'ehr',
		value: false,
		isLocked: true,
		description: 'EHRDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.Whiteboard]: getConfigurableMenu({
		title: 'whiteboard',
		value: false,
		isLocked: true,
		description: 'monitoringWhiteboardDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.DisplayControl]: getConfigurableMenu({
		title: 'whiteboardControls',
		value: false,
		isLocked: true,
		description: 'whiteboardControlsDesc',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.CareEventsForNurses]: getConfigurableMenu({
		title: 'careEventsForNurses',
		value: false,
		isLocked: true,
		description: 'careEventsForNursesDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.CareEventsForSafetyCompanions]: getConfigurableMenu({
		title: 'careEventsForSafetyCompanions',
		value: false,
		isLocked: true,
		description: 'careEventsForNursesDesc',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.Notes]: getConfigurableMenu({
		title: 'notes',
		value: false,
		isLocked: false,
		description: 'notesDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
		variant: { value: NoteVariants.SHOW_ON_EXPAND, label: 'showNotesOnExpand' },
		options: [
			{ value: NoteVariants.SHOW_ON_EXPAND, label: 'showNotesOnExpand' },
			{ value: NoteVariants.SHOW_UNDER_TILE, label: 'showNotesUnderTile' },
		],
	}),
	[MonitoringSettings.Precautions]: getConfigurableMenu({
		title: 'precautions',
		value: false,
		isLocked: false,
		description: 'precautionsDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.Recording]: getConfigurableMenu({
		title: 'recordTheSession',
		value: false,
		isLocked: true,
		description: 'recordingDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
	}),
	[MonitoringSettings.Privacy]: getConfigurableMenu({
		title: 'enablePrivacyMode',
		value: false,
		isLocked: false,
		description: 'privacyDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
	}),
	[MonitoringSettings.MonitoringHealthData]: getConfigurableMenu({
		title: 'healthData',
		value: false,
		isLocked: false,
		description: 'monitoringHealthDataDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
	}),
	[MonitoringSettings.PatientInfo]: getConfigurableMenu({
		title: 'patientInfoBox',
		value: false,
		isLocked: false,
		description: 'patientInfoDescription',
		category: MonitoringFeatureFlagCategories.PATIENT_INFO,
		...(isCompanyOrHSLevel && {
			otherConfigsToDisable: [
				MonitoringSettings.PatientName,
				MonitoringSettings.PatientAge,
				MonitoringSettings.NurseNameNumber,
				MonitoringSettings.NurseAssistantNameNumber,
				MonitoringSettings.TlNameNumber,
				MonitoringSettings.PrimaryAdverseEvent,
				MonitoringSettings.PrimaryPatientCondition,
				MonitoringSettings.PrimaryPatientRiskFactor,
				MonitoringSettings.PreferredLanguage,
				MonitoringSettings.TitleBarNotes,
				MonitoringSettings.AdditionalPatientNotes,
			],
			dependentConfigsTitle: 'patientInfoConfigsTitle',
		}),
	}),
	...(isCompanyOrHSLevel && {
		[MonitoringSettings.PatientName]: getConfigurableMenu({
			title: 'patientName',
			value: false,
			isLocked: false,
			description: 'patientNameDescription',
			category: MonitoringFeatureFlagCategories.PATIENT_INFO,
			dependentFrom: MonitoringSettings.PatientInfo,
		}),
		[MonitoringSettings.PatientAge]: getConfigurableMenu({
			title: 'patientAge',
			value: false,
			isLocked: false,
			description: 'patientAgeDescription',
			category: MonitoringFeatureFlagCategories.PATIENT_INFO,
			dependentFrom: MonitoringSettings.PatientInfo,
		}),
		[MonitoringSettings.NurseNameNumber]: getConfigurableMenu({
			title: 'nurseNameNumber',
			value: false,
			isLocked: false,
			description: 'nurseNameNumberDescription',
			category: MonitoringFeatureFlagCategories.PATIENT_INFO,
			dependentFrom: MonitoringSettings.PatientInfo,
		}),
		[MonitoringSettings.NurseAssistantNameNumber]: getConfigurableMenu({
			title: 'nurseAssistantNameNumber',
			value: false,
			isLocked: false,
			description: 'nurseAssistantNameNumberDescription',
			category: MonitoringFeatureFlagCategories.PATIENT_INFO,
			dependentFrom: MonitoringSettings.PatientInfo,
		}),
		[MonitoringSettings.TlNameNumber]: getConfigurableMenu({
			title: 'tlNameNumber',
			value: false,
			isLocked: false,
			description: 'tlNameNumberDescription',
			category: MonitoringFeatureFlagCategories.PATIENT_INFO,
			dependentFrom: MonitoringSettings.PatientInfo,
		}),
		[MonitoringSettings.PrimaryAdverseEvent]: getConfigurableMenu({
			title: 'primaryAdverseEvent',
			value: false,
			isLocked: false,
			description: 'primaryAdverseEventDescription',
			category: MonitoringFeatureFlagCategories.PATIENT_INFO,
			dependentFrom: MonitoringSettings.PatientInfo,
		}),
		[MonitoringSettings.PrimaryPatientCondition]: getConfigurableMenu({
			title: 'primaryPatientCondition',
			value: false,
			isLocked: false,
			description: 'primaryPatientConditionDescription',
			category: MonitoringFeatureFlagCategories.PATIENT_INFO,
			dependentFrom: MonitoringSettings.PatientInfo,
		}),
		[MonitoringSettings.PrimaryPatientRiskFactor]: getConfigurableMenu({
			title: 'primaryPatientRiskFactor',
			value: false,
			isLocked: false,
			description: 'primaryPatientRiskFactorDescription',
			category: MonitoringFeatureFlagCategories.PATIENT_INFO,
			dependentFrom: MonitoringSettings.PatientInfo,
		}),
		[MonitoringSettings.PreferredLanguage]: getConfigurableMenu({
			title: 'preferredLanguage',
			value: false,
			isLocked: false,
			description: 'preferredLanguageDescription',
			category: MonitoringFeatureFlagCategories.PATIENT_INFO,
			dependentFrom: MonitoringSettings.PatientInfo,
		}),
		[MonitoringSettings.TitleBarNotes]: getConfigurableMenu({
			title: 'titleBarNotes',
			value: false,
			isLocked: false,
			description: 'titleBarNotesDescription',
			category: MonitoringFeatureFlagCategories.PATIENT_INFO,
			dependentFrom: MonitoringSettings.PatientInfo,
		}),
		[MonitoringSettings.AdditionalPatientNotes]: getConfigurableMenu({
			title: 'additionalPatientNotes',
			value: false,
			isLocked: false,
			description: 'additionalPatientNotesDescription',
			category: MonitoringFeatureFlagCategories.PATIENT_INFO,
			dependentFrom: MonitoringSettings.PatientInfo,
		}),
	}),
	[MonitoringSettings.ForwardToNurses]: getConfigurableMenu({
		title: 'forwardToNurses',
		value: false,
		isLocked: true,
		description: 'forwardToNursesDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
	}),
	[MonitoringSettings.VerbalRedirection]: getConfigurableMenu({
		title: 'voiceAnnouncements',
		value: false,
		isLocked: true,
		description: 'voiceAnnouncementsDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
		isNotUserPreference: true,
		isExpandable: true,
	}),
	[MonitoringSettings.PatientOutOfRoom]: getConfigurableMenu({
		title: 'patientOutOfRoom',
		value: false,
		isLocked: true,
		description: 'patientOutOfRoomDescription',
		category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
		isNotUserPreference: true,
		isExpandable: true,
	}),
	[MonitoringSettings.DiscontinueMonitoring]: getConfigurableMenu({
		title: 'discontinuingMonitoringReason',
		value: false,
		isLocked: true,
		description: 'discontinueMonitoringDescription',
		category: MonitoringFeatureFlagCategories.GENERAL,
		isNotUserPreference: true,
		isExpandable: true,
	}),
	...(role !== UserRoles.VIRTUAL_SITTER && {
		[MonitoringSettings.RaiseAlert]: getConfigurableMenu({
			title: 'raiseAnAlertManually',
			value: false,
			isLocked: true,
			description: 'raiseAlertDescription',
			category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_NURSES,
		}),
		[MonitoringSettings.DisableVisits]: getConfigurableMenu({
			title: 'disableVisits',
			value: false,
			isLocked: true,
			description: 'disableVisitsDescription',
			category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
		}),
	}),
	...(isCompanyOrHSLevel && {
		[MonitoringSettings.EnableMultipleFeedAudio]: getConfigurableMenu({
			title: 'enableMultipleFeedAudio',
			value: false,
			isLocked: false,
			description: 'enableMultipleFeedAudioDescription',
			category: MonitoringFeatureFlagCategories.COMMUNICATE_WITH_PATIENT,
		}),
		[MonitoringSettings.MixedMonitoringView]: getConfigurableMenu({
			title: 'mixedMonitoringView',
			value: false,
			isLocked: true,
			description: 'mixedMonitoringViewDescription',
			category: MonitoringFeatureFlagCategories.GENERAL,
			variant: { value: MixedViewVariants.VIDEO_MONITORING, label: 'defaultVideoMonitoring' },
			options: [
				{ value: MixedViewVariants.VIDEO_MONITORING, label: 'defaultVideoMonitoring' },
				{ value: MixedViewVariants.AMBIENT_MONITORING, label: 'defaultAmbientMonitoring' },
			],
			isNotUserPreference: true,
		}),
	}),
});

export const configurableIntegrationMenu = () => ({
	[IntegrationTypesSettings.AZURE_AD]: getConfigurableMenu({
		title: 'azureAd',
		value: false,
		description: 'externalTypeDescription',
		category: IntegrationFeaturesCategories.EXTERNAL_IDENTITY_PROVIDERS,
	}),
	[IntegrationTypesSettings.PING_FEDERATE]: getConfigurableMenu({
		title: 'pingFederate',
		value: false,
		description: 'externalTypeDescription',
		category: IntegrationFeaturesCategories.EXTERNAL_IDENTITY_PROVIDERS,
	}),
	[IntegrationTypesSettings.DUO_SSO]: getConfigurableMenu({
		title: 'duo',
		value: false,
		description: 'externalTypeDescription',
		category: IntegrationFeaturesCategories.EXTERNAL_IDENTITY_PROVIDERS,
	}),
	[IntegrationTypesSettings.OKTA]: getConfigurableMenu({
		title: 'okta',
		value: false,
		description: 'externalTypeDescription',
		category: IntegrationFeaturesCategories.EXTERNAL_IDENTITY_PROVIDERS,
	}),
	[IntegrationTypesSettings.HILL_ROM]: getConfigurableMenu({
		title: 'hillRom',
		value: false,
		description: 'externalTypeDescription',
		category: IntegrationFeaturesCategories.NURSE_CALLS_SYSTEMS,
	}),
	[IntegrationTypesSettings.EPIC]: getConfigurableMenu({
		title: 'epic',
		value: false,
		description: 'ehrDescription',
		category: IntegrationFeaturesCategories.EHR,
	}),
	[IntegrationTypesSettings.CERNER]: getConfigurableMenu({
		title: 'cerner',
		value: false,
		description: 'ehrDescription',
		category: IntegrationFeaturesCategories.EHR,
	}),
	[IntegrationTypesSettings.HL7_V2]: getConfigurableMenu({
		title: 'hl7v2',
		value: false,
		description: 'hl7v2Description',
		category: IntegrationFeaturesCategories.EHR,
	}),
	[IntegrationTypesSettings.TELEHEALTH]: getConfigurableMenu({
		title: 'telehealth',
		value: false,
		isLocked: false,
		description: 'telehealthDescription',
		category: IntegrationFeaturesCategories.PATIENT_ENGAGEMENT,
	}),
	[IntegrationTypesSettings.EVIDEON]: getConfigurableMenu({
		title: 'evideon',
		value: false,
		isLocked: false,
		description: 'evideoDescription',
		category: IntegrationFeaturesCategories.PATIENT_ENGAGEMENT,
	}),
	[IntegrationTypesSettings.PCARE]: getConfigurableMenu({
		title: 'pcare',
		value: false,
		isLocked: false,
		description: 'pcareDescription',
		category: IntegrationFeaturesCategories.PATIENT_ENGAGEMENT,
	}),
	[IntegrationTypesSettings.GET_WELL]: getConfigurableMenu({
		title: 'getwell',
		value: false,
		isLocked: false,
		description: 'getwellDescription',
		category: IntegrationFeaturesCategories.PATIENT_ENGAGEMENT,
	}),
	[IntegrationTypesSettings.SONIFI]: getConfigurableMenu({
		title: 'sonifi',
		value: false,
		isLocked: false,
		description: 'sonifiDescription',
		category: IntegrationFeaturesCategories.PATIENT_ENGAGEMENT,
	}),
	[IntegrationTypesSettings.MDM]: getConfigurableMenu({
		title: 'mdm',
		value: false,
		isLocked: false,
		description: 'mdmDescription',
		category: IntegrationFeaturesCategories.PATIENT_ENGAGEMENT,
	}),
	[IntegrationTypesSettings.GLOBO]: getConfigurableMenu({
		title: 'globo',
		value: false,
		description: 'translationTypeDescription',
		category: IntegrationFeaturesCategories.TRANSLATION_SERVICES,
	}),
	[IntegrationTypesSettings.AMN]: getConfigurableMenu({
		title: 'amn',
		value: false,
		description: 'translationTypeDescription',
		category: IntegrationFeaturesCategories.TRANSLATION_SERVICES,
	}),
	[IntegrationTypesSettings.ALEXA]: getConfigurableMenu({
		title: 'alexaEnabled',
		value: false,
		description: 'enableAlexaConfiguration',
		category: IntegrationFeaturesCategories.ALEXA,
	}),
});

export const ThemeTypes = {
	LIGHT: 'light',
	DARK: 'dark',
};

export const themeOptions = [
	{
		id: 0,
		value: ThemeTypes.LIGHT,
		icon: 'light_mode',
	},
	{
		id: 1,
		value: ThemeTypes.DARK,
		icon: 'dark_mode',
	},
];

export const talkToPatientTypes = intl => [
	{
		id: 0,
		content: intl.formatMessage({ id: 'audioOnly' }),
		value: TalkToPatient.AUDIO_ONLY,
	},
	{
		id: 1,
		content: intl.formatMessage({ id: 'audioVideo' }),
		value: TalkToPatient.AUDIO_VIDEO,
	},
];

const getConfigurableCallMenu = (description, value) => ({ value, description });

export const ConfigurableDigitalBackgroundSettings = {
	[CompanySettings.DIGITAL_BACKGROUND]: getConfigurableCallMenu('providerCallBackgrounds', 'false'),
	[CompanySettings.HS_DEFAULT_BACKGROUND]: getConfigurableCallMenu('defaultHSBackground', 'none'),
	[CompanySettings.HELLO_DEFAULT_BACKGROUND]: getConfigurableCallMenu('helloDefaultBackground', 'false'),
	[CompanySettings.HELLO_DEFAULT_WALLPAPER]: getConfigurableCallMenu('helloDefaultWallpaper', ''),
	[CompanySettings.AUDIO_CALL_BACKGROUND]: getConfigurableCallMenu('helloAudioCallBackground', 'false'),
};

export const PTZCameraSettings = {
	REVERSE_CAMERA: 19,
};

export const GeneralSettings = {
	PoolingFlow: 51,
	ConversationHistory: 52,
	Notifications: 53,
	AiNotifications: 285,
	MonitoringSessions: 54,
	MonitoringPrecautionsLegend: 14,
	NICU_CHAT: 50, // TEMPORARY
	CONVERSATION_FILES: 104,
	CONVERSATION_PHOTOS: 105,
	CaregiversName: 193,
	RegistrationForm: 313,
};

// Configurations View enums
export const ConfigHistoryTypes = {
	UserIdle: '1',
	Devices: '2',
	ADs: '3',
	TeamConfigurationProfiles: '4',
	NetworkBandwidth: '5',
	NetworkWiFiBand: '6',
	NetworkAccess: '7',
	NtpConfigurations: '8',
};

export const TeamTypeLabels = {
	4: 'Health System',
	5: 'Region',
	6: 'Hospital',
	7: 'Department',
	8: 'Floor',
};

export const TVs = {
	LG: { id: 1, value: 'LG (MPI)' },
	SAMSUNG: { id: 2, value: 'Samsung (Exlink)' },
	CEC: { id: 9, value: 'CEC' },
	TELEHEALTH_CONTROLLED: { id: 3, value: 'Telehealth' },
	EVIDEON: { id: 4, value: 'eVideon' },
	PCARE: { id: 5, value: 'pCare' },
	GET_WELL: { id: 6, value: 'GetWell' },
	SONIFY: { id: 7, value: 'Sonifi' },
	MDM: { id: 8, value: 'MDM' },
};

export const EndCallSource = intl => ({
	TV_CHANNELS: { id: 0, value: intl.formatMessage({ id: 'tvChannels' }) },
	HDMI_1: { id: 1, value: 'HDMI 1' },
	HDMI_2: { id: 2, value: 'HDMI 2' },
	HDMI_3: { id: 3, value: 'HDMI 3' },
	PREVIOUS_STATE: { id: 4, value: intl.formatMessage({ id: 'previousState' }) },
});

export const TeamConfigurationIntegrationTypes = {
	TELEHEALTH: 1,
	PCARE: 2,
	EVIDEON: 3,
	GET_WELL: 4,
	SONIFI: 5,
	MDM: 6,
};

export const VisitorMenu = {
	DEVICES: 67,
	CHAT: 50,
};

export const getAdminConfigurableMenu = showItem => ({
	[UserRoles.SUPER_ADMIN]: {
		[SuperAdminMenu.COMPANIES]: { isGeneralSetting: false, reDirectUrl: '/companies', value: showItem, orderNo: 1 },
		[SuperAdminMenu.USER_MANAGEMENT]: { isGeneralSetting: false, reDirectUrl: '/users', value: showItem, orderNo: 2 },
		[SuperAdminMenu.AUDIT_LOGS]: { isGeneralSetting: false, reDirectUrl: '/audit-logs', value: showItem, orderNo: 3 },
	},
	[UserRoles.ADMIN]: {
		[AdminMenu.ORGANIZATION]: { isGeneralSetting: false, reDirectUrl: '/health-system', value: true, orderNo: 1 },
		[AdminMenu.CALL_LOGS]: { isGeneralSetting: false, reDirectUrl: '/call-logs', value: true, orderNo: 2 },
		[AdminMenu.USER_MANAGEMENT]: { isGeneralSetting: false, reDirectUrl: '/users', value: true, orderNo: 3 },
		[AdminMenu.CONFIGURATION]: { isGeneralSetting: false, reDirectUrl: '/configurations', value: true, orderNo: 4 },
		[AdminMenu.AUDIT_LOGS]: { isGeneralSetting: false, reDirectUrl: '/audit-logs', value: showItem, orderNo: 5 },
		[AdminMenu.DASHBOARD]: { isGeneralSetting: false, reDirectUrl: '/dashboard', value: showItem, orderNo: 6 },
		[AdminMenu.FLEET_MANAGEMENT]: { isGeneralSetting: false, reDirectUrl: '/fleet-management', value: showItem, orderNo: 7 },
	},
	[UserRoles.SUPER_USER]: {
		[SuperUserMenu.ORGANIZATION]: { isGeneralSetting: false, reDirectUrl: '/health-system', value: showItem, orderNo: 1 },
		[SuperUserMenu.CALL_LOGS]: { isGeneralSetting: false, reDirectUrl: '/call-logs', value: showItem, orderNo: 2 },
		[SuperUserMenu.USER_MANAGEMENT]: { isGeneralSetting: false, reDirectUrl: '/users', value: showItem, orderNo: 3 },
		[SuperUserMenu.CONFIGURATION]: { isGeneralSetting: false, reDirectUrl: '/configurations', value: showItem, orderNo: 4 },
		[SuperUserMenu.QUEUE_MANAGEMENT]: { isGeneralSetting: false, reDirectUrl: '/queue-management', value: showItem, orderNo: 5 },
		[SuperUserMenu.FLEET_MANAGEMENT]: { isGeneralSetting: false, reDirectUrl: '/fleet-management', value: showItem, orderNo: 6 },
		[GeneralSettings.CONVERSATION_FILES]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 15 },
		[GeneralSettings.CONVERSATION_PHOTOS]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 16 },
		[SuperUserMenu.DASHBOARD]: { isGeneralSetting: false, reDirectUrl: '/dashboard', value: showItem, orderNo: 17 },
	},
	[UserRoles.DOCTOR]: {
		[DoctorMenu.HOME]: { isGeneralSetting: false, reDirectUrl: '/doctor-home', component: null, value: showItem, orderNo: 1 },
		[DoctorMenu.SESSIONS_QUEUE]: {
			isGeneralSetting: false,
			reDirectUrl: '/sessions-queue',
			value: showItem,
			orderNo: 2,
		},
		[DoctorMenu.WAITING_ROOM]: {
			isGeneralSetting: false,
			reDirectUrl: '/waiting-room',
			value: showItem,
			orderNo: 3,
		},
		[DoctorMenu.WAITING_ROOM_IN_PERSON]: {
			isGeneralSetting: false,
			reDirectUrl: '/waiting-room-inperson',
			value: showItem,
			orderNo: 11,
		},
		[DoctorMenu.PATIENTS]: { isGeneralSetting: false, reDirectUrl: '/patients', value: showItem, orderNo: 4 },
		[DoctorMenu.HOSPITAL_AT_HOME]: { isGeneralSetting: false, reDirectUrl: '/hospital-at-home', value: showItem, orderNo: 8 },
		[DoctorMenu.APPOINTMENTS]: { isGeneralSetting: false, reDirectUrl: '/appointments', value: showItem, orderNo: 5 },
		[DoctorMenu.ROUNDING]: { isGeneralSetting: false, reDirectUrl: '/health-system', value: showItem, orderNo: 6 },
		[DoctorMenu.RPM]: { isGeneralSetting: false, reDirectUrl: '/patient-monitoring', value: showItem, orderNo: 7 },
		[DoctorMenu.LOGISTICS]: {
			isGeneralSetting: false,
			reDirectUrl: '/logistics',
			value: showItem,
			orderNo: 11,
		},
		[GeneralSettings.ConversationHistory]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 8 },
		[GeneralSettings.Notifications]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 9 },
		[GeneralSettings.CONVERSATION_FILES]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 15 },
		[GeneralSettings.CONVERSATION_PHOTOS]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 16 },
		[GeneralSettings.CaregiversName]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 17 },
		[GeneralAndMenuSettings.REGISTRATION_FORM]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 18 },
	},
	[UserRoles.PATIENT]: {
		[PatientMenu.HOME]: { isGeneralSetting: false, reDirectUrl: '/patient-home', value: showItem, orderNo: 1 },
		[PatientMenu.RECENT]: { isGeneralSetting: false, reDirectUrl: '/recent', value: showItem, orderNo: 2 },
		[PatientMenu.APPOINTMENTS]: { isGeneralSetting: false, reDirectUrl: '/appointments', value: showItem, orderNo: 3 },
		[PatientMenu.HEALTH]: { isGeneralSetting: false, reDirectUrl: '/health-data', value: showItem, orderNo: 4 },
		[PatientMenu.SUMMARY]: { isGeneralSetting: false, reDirectUrl: '/summary', value: showItem, orderNo: 5 },
		[PatientMenu.HELLO]: { isGeneralSetting: false, reDirectUrl: '/my-hello-devices', value: showItem, orderNo: 6 },
		[GeneralSettings.ConversationHistory]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 7 },
		[GeneralSettings.Notifications]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 8 },
	},
	[UserRoles.NURSE]: {
		[NurseMenu.AMBIENT_MONITORING]: { isGeneralSetting: false, reDirectUrl: '/care-notifications', value: showItem, orderNo: 7 },
	},
	[UserRoles.DIGITAL_CLINICIAN]: {
		[DigitalClinicianMenu.CHECK_IN]: {
			isGeneralSetting: false,
			reDirectUrl: '/check-in-dc',
			value: true,
			orderNo: 1,
		},
		[DigitalClinicianMenu.PATIENTS]: {
			isGeneralSetting: false,
			reDirectUrl: '/digital-clinician/patients',
			value: showItem,
			orderNo: 2,
		},
		[DigitalClinicianMenu.RPM]: { isGeneralSetting: false, reDirectUrl: '/vital-signs-monitoring', value: showItem, orderNo: 3 },
		[DigitalClinicianMenu.VISITS]: { isGeneralSetting: false, reDirectUrl: '/front-desk-visits', value: showItem, orderNo: 4 },
		[DigitalClinicianMenu.REGISTER_PATIENT]: {
			isGeneralSetting: false,
			reDirectUrl: '/check-in-patient',
			value: showItem,
			orderNo: 5,
		},
	},
	[UserRoles.VISITOR]: {
		[VisitorMenu.DEVICES]: { isGeneralSetting: false, reDirectUrl: '/devices', value: true, orderNo: 1 },
		[GeneralSettings.CONVERSATION_FILES]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 15 },
		[GeneralSettings.CONVERSATION_PHOTOS]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 16 },
		// [FamilyMemberMenu.CHAT]: { isGeneralSetting: false, reDirectUrl: '', value: showItem, orderNo: 2 },
	},
	[UserRoles.VIRTUAL_SITTER]: {
		[VirtualSitterMenu.MONITORING]: { isGeneralSetting: false, reDirectUrl: '/monitoring', value: showItem, orderNo: 1 },
		[VirtualSitterMenu.AMBIENT_MONITORING]: {
			isGeneralSetting: false,
			reDirectUrl: '/care-notifications',
			value: showItem,
			orderNo: 2,
		},
		[VirtualSitterMenu.TEAMS]: { isGeneralSetting: false, reDirectUrl: '/teams', value: showItem, orderNo: 3 },
		[GeneralSettings.MonitoringPrecautionsLegend]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 13 },
		[GeneralSettings.MonitoringSessions]: { isGeneralSetting: true, reDirectUrl: '', value: showItem, orderNo: 14 },
	},
	[UserRoles.GUEST]: {},
});

export const mobileSettings = {
	Ear: 68,
	Throat: 69,
	Heart: 70,
	Lungs: 71,
	HeartRate: 72,
	OxygenSaturation: 73,
	Temperature: 74,
	Skin: 75,
	BloodPressure: 76,
	ECG: 77,
	BloodGlucose: 78,
	Weight: 79,
	Spirometer: 80,
	Abdomen: 81,
};

export const buildConfigSettings = (preferences, configurableMenu) => {
	const newConfig = {};
	if (!configurableMenu) {
		return null;
	}
	Object.keys(configurableMenu).forEach(key => {
		const foundPreference = preferences.find(preference => preference.preferenceTypeId === +key);
		if (foundPreference) {
			newConfig[key] = { ...configurableMenu[key], value: foundPreference.value };
		} else {
			newConfig[key] = { ...configurableMenu[key], value: true };
		}
	});
	return newConfig;
};

export const buildRoleSettings = async (newSettings, defaultConfigurations = {}, role) => {
	const buildSettings = {};
	Object.keys(defaultConfigurations).forEach(key => {
		const roundingConfig = newSettings.some(config => config.settingTypeId === +key && config.roleId === role);
		buildSettings[key] = roundingConfig;
	});
	return buildSettings;
};

export const buildHealthSystemMenuConfigs = async settings => {
	const result = _.cloneDeep(getAdminConfigurableMenu(false));
	CompanyRoleObjects.forEach(item => {
		const filtredSettings = settings.filter(setting => setting.roleId === item.id);
		filtredSettings.forEach(setting => {
			if (result[item.role][setting.settingTypeId]) {
				result[item.role][setting.settingTypeId] = {
					...result[item.role][setting.settingTypeId],
					value: true,
					...(setting.variant && {
						variant: setting.variant,
					}),
				};
			}
		});
	});
	return result;
};

export const defaultCustomAudioRoutingList = intl => [
	{
		enum: AudioRoutingDeviceTypes.HELLO_1,
		name: 'HELLO 1',
		isLocked: false,
		value: {
			talkToPatient: AudioRoutingOption.ALWAYS_FROM_HELLO,
			monitoring: AudioRoutingOption.ALWAYS_FROM_HELLO,
			viewPatient: AudioRoutingOption.ALWAYS_FROM_HELLO,
		},
		options: [
			{
				id: 0,
				content: intl.formatMessage({ id: 'alwaysFromHello' }),
				value: AudioRoutingOption.ALWAYS_FROM_HELLO,
			},
			{
				id: 1,
				content: intl.formatMessage({ id: 'routesToTvPatientNurse' }),
				value: AudioRoutingOption.TV_PATIENT_NURSE,
			},
			{
				id: 2,
				content: intl.formatMessage({ id: 'routesToTvSourceAvailable' }),
				value: AudioRoutingOption.TV_SOURCE_AVAILABLE,
			},
			{
				id: 3,
				content: intl.formatMessage({ id: 'alwaysPillowSpeaker' }),
				value: AudioRoutingOption.ALWAYS_PILLOW_SPEAKER,
			},
		],
		image: 'hello-2.svg',
	},
	{
		enum: AudioRoutingDeviceTypes.HELLO_2,
		name: 'HELLO 2',
		isLocked: false,
		value: {
			talkToPatient: AudioRoutingOption.ALWAYS_FROM_HELLO,
			monitoring: AudioRoutingOption.ALWAYS_FROM_HELLO,
			viewPatient: AudioRoutingOption.ALWAYS_FROM_HELLO,
		},
		options: [
			{
				id: 0,
				content: intl.formatMessage({ id: 'alwaysFromHello' }),
				value: AudioRoutingOption.ALWAYS_FROM_HELLO,
			},
			{
				id: 1,
				content: intl.formatMessage({ id: 'routesToTvPatientNurse' }),
				value: AudioRoutingOption.TV_PATIENT_NURSE,
			},
			{
				id: 2,
				content: intl.formatMessage({ id: 'routesToTvSourceAvailable' }),
				value: AudioRoutingOption.TV_SOURCE_AVAILABLE,
			},
			{
				id: 3,
				content: intl.formatMessage({ id: 'alwaysPillowSpeaker' }),
				value: AudioRoutingOption.ALWAYS_PILLOW_SPEAKER,
			},
		],
		image: 'hello-2.svg',
	},
	{
		enum: AudioRoutingDeviceTypes.HELLO_2_PLUS,
		name: 'HELLO 2+',
		isLocked: false,
		value: {
			talkToPatient: AudioRoutingOption.ALWAYS_FROM_HELLO,
			monitoring: AudioRoutingOption.ALWAYS_FROM_HELLO,
			viewPatient: AudioRoutingOption.ALWAYS_FROM_HELLO,
		},
		options: [
			{
				id: 0,
				content: intl.formatMessage({ id: 'alwaysFromHello' }),
				value: AudioRoutingOption.ALWAYS_FROM_HELLO,
			},
			{
				id: 1,
				content: intl.formatMessage({ id: 'routesToTvPatientNurse' }),
				value: AudioRoutingOption.TV_PATIENT_NURSE,
			},
			{
				id: 2,
				content: intl.formatMessage({ id: 'routesToTvSourceAvailable' }),
				value: AudioRoutingOption.TV_SOURCE_AVAILABLE,
			},
			{
				id: 3,
				content: intl.formatMessage({ id: 'alwaysPillowSpeaker' }),
				value: AudioRoutingOption.ALWAYS_PILLOW_SPEAKER,
			},
		],
		image: 'hello-2-plus.svg',
	},
	{
		enum: AudioRoutingDeviceTypes.HELLO_3,
		name: 'HELLO 3',
		isLocked: false,
		value: {
			talkToPatient: AudioRoutingOption.ALWAYS_FROM_HELLO,
			monitoring: AudioRoutingOption.ALWAYS_FROM_HELLO,
			viewPatient: AudioRoutingOption.ALWAYS_FROM_HELLO,
		},
		options: [
			{
				id: 0,
				content: intl.formatMessage({ id: 'alwaysFromHello' }),
				value: AudioRoutingOption.ALWAYS_FROM_HELLO,
			},
			{
				id: 1,
				content: intl.formatMessage({ id: 'routesToTvPatientNurse' }),
				value: AudioRoutingOption.TV_PATIENT_NURSE,
			},
			{
				id: 2,
				content: intl.formatMessage({ id: 'routesToTvSourceAvailable' }),
				value: AudioRoutingOption.TV_SOURCE_AVAILABLE,
			},
			{
				id: 3,
				content: intl.formatMessage({ id: 'alwaysPillowSpeaker' }),
				value: AudioRoutingOption.ALWAYS_PILLOW_SPEAKER,
			},
		],
		image: 'hello-2.svg',
	},
	{
		enum: AudioRoutingDeviceTypes.PHONE,
		name: intl.formatMessage({ id: 'phone' }),
		isLocked: false,
		value: {
			talkToPatient: AudioRoutingOption.ALWAYS_FROM_PHONE,
			monitoring: AudioRoutingOption.ALWAYS_FROM_PHONE,
			viewPatient: AudioRoutingOption.ALWAYS_FROM_PHONE,
		},
		options: [
			{
				id: 0,
				content: intl.formatMessage({ id: 'alwaysFromPhone' }),
				value: AudioRoutingOption.ALWAYS_FROM_PHONE,
			},
			{
				id: 1,
				content: intl.formatMessage({ id: 'routeExternalDetected' }),
				value: AudioRoutingOption.EXTERNAL_SPEAKER_DETECTED,
			},
		],
		image: 'iphone.svg',
	},
	{
		enum: AudioRoutingDeviceTypes.TABLET,
		name: intl.formatMessage({ id: 'tablet' }),
		isLocked: false,
		value: {
			talkToPatient: AudioRoutingOption.ALWAYS_FROM_TABLET,
			monitoring: AudioRoutingOption.ALWAYS_FROM_TABLET,
			viewPatient: AudioRoutingOption.ALWAYS_FROM_TABLET,
		},
		options: [
			{
				id: 0,
				content: intl.formatMessage({ id: 'alwaysFromTablet' }),
				value: AudioRoutingOption.ALWAYS_FROM_TABLET,
			},
			{
				id: 1,
				content: intl.formatMessage({ id: 'routeExternalDetected' }),
				value: AudioRoutingOption.EXTERNAL_SPEAKER_DETECTED,
			},
		],
		image: 'tablet.svg',
	},
];

export const AdminsLogTypes = {
	HEALTH_SYSTEM_SETTINGS: { value: 122, label: 'health.system.settings.updated' },
	HOSPITAL_SETTINGS: { value: 100, label: 'hospital.settings.updated' },
	DEPARTMENT_SETTINGS: { value: 102, label: 'department.settings.updated' },
	FLOOR_SETTINGS: { value: 103, label: 'floor.settings.updated' },
	ROOM_SETTINGS: { value: 99, label: 'room.settings.updated' },
	DEVICE_SETTINGS: { value: 170, label: 'device.settings.updated' },
};

export const menuUrls = [
	'/companies',
	'/users',
	'/audit-logs',
	'/health-system',
	'/dashboard',
	'/call-logs',
	'/configurations',
	'/doctor-home',
	'/nurse-home',
	'/patient-home',
	'/waiting-room',
	'/waiting-room-inperson',
	'/patients',
	'/appointments',
	'/vital-signs-monitoring',
	'/recent',
	'/health-data',
	'/summary',
	'/my-hello-devices',
	'/nurses',
	'/teams',
	'/monitoring',
	'/patient-monitoring',
	'/waiting-results',
	'/visitors',
	'/check-in-dc',
	'/digital-clinician/patients',
	'/front-desk-visits',
	'/queue-management',
	'/sessions-queue',
	'/nurse-patients',
	'/care-notifications',
	'/logistics',
	'/care-events-dashboard',
	'/fleet-management',
	'/hospital-at-home',
];

export const LandingPrivateRoutes = {
	NURSE_HOME: '/nurse-home',
	DOCTOR_HOME: '/doctor-home',
	CHECK_IN_DC: '/check-in-dc',
};

export const AiSettingsTabs = [
	{ id: 0, title: translate('monitoring') },
	{ id: 1, title: translate('aiSettings') },
];

export const LanguageTypes = {
	'en-US': 'english',
	'ar-AE': 'arabic',
	'de-DE': 'german',
	sq: 'albanian',
	'es-ES': 'spanish',
};

const roundingRolesList = [
	{ value: UserRoles.NURSE, label: translate('virtualCareProvider') },
	{ value: UserRoles.DOCTOR, label: translate('doctor') },
];

const customizationRolesList = [
	...roundingRolesList,
	{ value: UserRoles.VIRTUAL_SITTER, label: translate('virtualSitter') },
	{ value: UserRoles.DIGITAL_CLINICIAN, label: translate('digitalClinician') },
	{ value: UserRoles.SUPER_USER, label: translate('superUser') },
];

export const hsFeatureFlagsCategories = ({ selectedRole, isUserExperienceHSEditable, anyPatientTabEnabled }) =>
	[
		{
			title: translate('roleCustomization'),
			settingCategory: SettingsCategory.CUSTOMIZATION,
			defaultConfigurations: configurableGeneralAndMenuSettings(selectedRole.value),
			roleList: customizationRolesList,
			categoryOrder: Object.values(GeneralAndMenuFeaturesCategories),
		},
		{
			title: translate('monitoring'),
			settingCategory: SettingsCategory.MONITORING,
			defaultConfigurations: configurableMonitoringMenu(getUserRole(), true),
			categoryOrder: Object.values(MonitoringFeatureFlagCategories),
		},
		{
			title: translate('rounding'),
			settingCategory: SettingsCategory.ROUNDING,
			defaultConfigurations: configurableRoundingMenu(selectedRole.value),
			roleList: roundingRolesList,
			categoryOrder: Object.values(RoundingFeaturesCategories),
		},
		{
			title: translate('helloCareEngagement'),
			settingCategory: SettingsCategory.HELLO_CARE_ENGAGEMENT,
			defaultConfigurations: configurableHelloCareEngagement(),
		},
		{
			title: translate('aiSettings'),
			settingCategory: SettingsCategory.AI_SETTINGS,
			defaultConfigurations: configurableAISettings(),
			categoryOrder: Object.values(AiSettingFeaturesCategory),
		},
		{
			title: translate('userExperience'),
			settingCategory: SettingsCategory.USER_EXPERIENCE,
			defaultConfigurations: configurableUserExperienceSettings(getUserRole(), isUserExperienceHSEditable),
			categoryOrder: Object.values(UserExperienceFeaturesCategories),
		},
		anyPatientTabEnabled && {
			title: translate('patients'),
			settingCategory: SettingsCategory.PATIENTS,
			defaultConfigurations: configurablePatientTabs(),
			categoryOrder: Object.values(PatientTabsFeaturesCategories),
		},
		{
			title: translate('voiceCommands'),
			settingCategory: SettingsCategory.VOICE_COMMANDS,
			defaultConfigurations: configurableVoiceCommandsSettings(),
		},
	].filter(Boolean);

// atm Hospital, Department, Floor, and Room have the same categories
export const hospitalFeatureFlagsCategories = () => [
	{
		title: translate('monitoring'),
		settingCategory: SettingsCategory.MONITORING,
		defaultConfigurations: configurableMonitoringMenu(getUserRole()),
		categoryOrder: Object.values(MonitoringFeatureFlagCategories),
	},
	{
		title: translate('aiSettings'),
		settingCategory: SettingsCategory.AI_SETTINGS,
		defaultConfigurations: configurableAISettings(),
		categoryOrder: Object.values(AiSettingFeaturesCategory),
	},
	{
		title: translate('voiceCommands'),
		settingCategory: SettingsCategory.VOICE_COMMANDS,
		defaultConfigurations: configurableVoiceCommandsSettings(),
	},
];
