import React, { useRef } from 'react';
import Grid from 'components/Grid.jsx';

const StatusMessage = ({message}) => {
	const clickCountRef = useRef(0);
	let clickTimeout;
	const handleMultipleClick = () => {
		clickCountRef.current += 1;
		if (clickTimeout) clearTimeout(clickTimeout);
		clickTimeout = setTimeout(() => {
			clickCountRef.current = 0;
		}, 500);
		if (clickCountRef.current >= 10) {
			window.Android?.goToSettings();
			clickCountRef.current = 0;
			clearTimeout(clickTimeout);
		}
	};
	return (
		<Grid columns='1fr' rows='1fr' stretch='100%' horizAlign='center' vertAlign='center'>
			<div className='member-error' onClick={handleMultipleClick}>
				<h2>
					<i>{message}</i>
				</h2>
			</div>
		</Grid>
	);
};

export default StatusMessage;
